/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import { Apiurl, GetLocalStorage } from '../../../Utils/Utils'
import axios from 'axios'
import Select from 'react-select';
import '../AdminGuidelines/AdminGuildlines.css'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom'
import { addSubCategorySectionTabData, deleteApplicationSectionsTabData, getFontFamilyData, getFormTabSubCategoryListData, getSectionsData, getSectionsDataSort, getSubCategoriesDropdown, getSubCategoryListData, handleEnableSectionsDisableData, updateSubCategoryTabSectionData } from '../../../services/api/admin'
import { BsChevronLeft } from 'react-icons/bs'
import { HiPlus } from 'react-icons/hi'
import ReactPaginate from 'react-paginate'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import sortByIcon from '../../../assets/images/sort.png';
import { Message } from '../../../components/message'
import { checkPatternWithSpecialCharacter, checkPatternFontSize } from '../../../Utils/common'

const ApplicationSections = () => {
    const navigate = useNavigate();
    const token = GetLocalStorage("userInfo")
    const [nameEn, setNameEn] = useState("")
    const [nameHi, setNameHi] = useState("")
    const [subCategoryId, setSubCategoryId] = useState()
    const [searchSubCategoryId, setSearchSubCategoryId] = useState()
    const [searchFormTabId, setSearchFormTabId] = useState()
    const [nameEnError, setNameEnError] = useState()
    const [nameHiError, setNameHiError] = useState()
    const [subCategoryIdError, setSubCategoryIdError] = useState()
    const [subCategoryList, setSubCategoryList] = useState([])
    const [editData, setEditData] = useState()
    const [formTabId, setFormTabId] = useState()
    const [formTabIdError, setFormTabIdError] = useState()
    const [sectionList, setSectionList] = useState([])
    const [sectionFullList, setSectionFullList] = useState([])
    const [formTabList, setFormTabList] = useState()
    const [searchFormTabList, setSearchFormTabList] = useState()
    const [fontFamily, setFontFamily] = useState();
    const [fontFamilyError, setFontFamilyError] = useState();

    const [fontColor, setFontColor] = useState();
    const [fontColorError, setFontColorError] = useState();

    const [fontSize, setFontSize] = useState('');
    const [fontSizeError, setFontSizeError] = useState();

    const [backgroundColor, setBackgroundColor] = useState();
    const [backgroundColorError, setBackgroundColorError] = useState();
    const [fontsList, setFontsList] = useState()
    const [deleteData, setDeleteData] = useState()
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [editContainer, setEditContainer] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [orderBy, setOrderBy] = useState("asc");

    const handleInputChange = async (e) => {
        const { name, value } = e.target;


        // Check for "nameEn" field  

        if (name === "nameEn") {

            if (await checkPatternWithSpecialCharacter(value)) {
                setNameEn(value);
            } else {
                e.preventDefault()
                return;
            }
        }

        // Check for "nameHi" field
        if (name === "nameHi") {
            if (await checkPatternWithSpecialCharacter(value)) {
                setNameHi(value);
            } else {
                e.preventDefault()
                return;
            }
        }


    }

    const handleKeyUpLoginDetails = (item) => {
        validateForm(item);
    };
    //validations
    const validateForm = (item) => {
        let isValid = true;
        if (item === "subCategoryId" || item === "both") {
            if (!subCategoryId) {
                setSubCategoryIdError("Please Select Sub Category");
                isValid = false;
            } else {
                setSubCategoryIdError("");
            }
        }
        if (item === "formTabId" || item === "both") {
            // if (!subCategoryId) {
            //     setSubCategoryIdError("Please Select Sub Category");
            //     isValid = false;
            // }
            if (!formTabId) {
                setFormTabIdError("Please Select Form Tab");
                isValid = false;
            } else {
                setFormTabIdError("");
            }
        }
        if (item === "nameEn" || item === "both") {
            // if (!formTabId) {
            //     setFormTabIdError("Please Select Form Tab");
            //     isValid = false;
            // }
            if (!nameEn || nameEn.trim() === "") {
                setNameEnError("Please Enter English Name");
                isValid = false;
            } else {
                setNameEnError("");
            }
        }
        if (item === "nameHi" || item === "both") {
            // if (!nameEn || nameEn.trim() === "") {
            //     setNameEnError("Please Enter English Name");
            //     isValid = false;
            // }
            if (!nameHi || nameHi.trim() === "") {
                setNameHiError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setNameHiError("");
            }
        }

        if (item === "family" || item === "both") {
            // if (!nameHi || nameHi.trim() === "") {
            //     setNameHiError("Please Enter Hindi Name");
            //     isValid = false;
            // }
            if (!fontFamily) {
                setFontFamilyError("Please Select Font Family");
                isValid = false;
            } else {
                setFontFamilyError("");
            }
        }

        if (item === "color" || item === "both") {
            // if (!fontFamily) {
            //     setFontFamilyError("Please Select Font Family");
            //     isValid = false;
            // }
            if (!fontColor || fontColor.trim() === "") {
                setFontColorError("Please Select Font Color");
                isValid = false;
            } else {
                setFontColorError("");
            }
        }

        if (item === "size" || item === "both") {
            // if (!fontColor || fontColor.trim() === "") {
            //     setFontColorError("Please Select Font Color");
            //     isValid = false;
            // }
            if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
                setFontSizeError("Please Enter Valid Font Size");
                isValid = false;
            } else {
                setFontSizeError("");
            }
        }

        if (item === "bgcolor" || item === "both") {
            // if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
            //     setFontSizeError("Please Enter Valid Font Size");
            //     isValid = false;
            // }
            if (!backgroundColor || backgroundColor.trim() === "") {
                setBackgroundColorError("Please Select Background Color");
                isValid = false;
            } else if (backgroundColor == fontColor) {
                setBackgroundColorError("Font color and background color cannot be same.");
                isValid = false;
            } else {
                setBackgroundColorError("");
            }
        }

        return isValid;
    };
    // add Category tab
    const addSubCategoryTab = async () => {
        if (!validateForm("both")) return
        try {
            // console.log("formTabId", formTabId)
            // return;
            const formData = new FormData();
            formData.append("sub_category_id", subCategoryId.value)
            formData.append("tab_id", formTabId.value)
            formData.append("name_en", nameEn)
            formData.append("name_hi", nameHi)
            formData.append("sort", 10)
            formData.append("status", "1")
            formData.append("font_family_id", fontFamily?.value)
            formData.append("font_color", fontColor)
            formData.append("font_size", fontSize)
            formData.append("background_color", backgroundColor)
            const data = await addSubCategorySectionTabData(formData)
            if (data?.status == 200) {
                getSections(currentPage)
                setEditContainer(false)
                setNameEn("")
                setNameHi("")
                setSubCategoryId("")
                setFormTabId("")
                setFontFamily("");
                setFontColor("")
                setFontSize("")
                setBackgroundColor("")
                setApiMessage({ type: 'success', message: "Section added" });
            }
        } catch (error) {
            setApiMessage({ type: 'error', message: "error occured" });
            console.error(error);
        }
    }
    // sub category list
    const getSubCategoryList = async () => {
        try {
            const subcategoryListData = await getSubCategoriesDropdown()
            const data = subcategoryListData?.data?.map(i => ({
                label: i?.category_name, value: i?.id
            }))
            setSubCategoryList(data)
        } catch (error) {
            console.log("error on sub category list: ".error)
            // navigate("/")

        };
    };
    // tabs list
    const getFormTabList = async (item) => {
        const formData = new FormData();
        if (item == "search") {
            formData.append("category_id", searchSubCategoryId.value)
        } else {
            formData.append("category_id", subCategoryId.value)
        }
        await getFormTabSubCategoryListData(formData)
            .then((e) => {
                const data = e?.data?.map(i => ({
                    label: i?.name_en, value: i?.id
                }))
                if (item == "search") {
                    console.log(data)
                    setSearchFormTabList(data)
                } else {
                    setFormTabList(data)
                }

            })
            .catch((error) => {
                console.log("fetch getFormTabSubCategoryListData  list", error)
                // navigate("/")
            });
    };
    //get font families
    const getFontFamily = async () => {
        try {
            const fontFamilyData = await getFontFamilyData()
            const data = fontFamilyData?.data?.map(i => ({
                label: i?.name, value: i?.id
            }))

            setFontsList(data)
        } catch (err) {
            console.log("font families error :", err)
        }
    };
    //get sections
    const getSections = async (pageNo) => {
        try {
            const sectionData = await getSectionsData(pageNo)
            const data = sectionData?.data.data
            setSectionList(data)
            setSectionFullList(data)
            setTotalPages(Math.ceil(sectionData?.data?.total / sectionData?.data?.per_page)); // Calculate total pages
        } catch (error) {
            console.log("error on section Data: ", error)
        }
    };

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        getSections(selectedPage.selected + 1); // Increment by 1 if the API starts page numbers from 1
    };


    const searchSortFromList = async (e, sortBy) => {
        try {
            const searchBy = e.target.value ? e.target.value : '';
            setOrderBy(orderBy == 'asc' ? 'desc' : 'asc')
            const sectionData = await getSectionsDataSort(sortBy, orderBy, searchBy)
            const data = sectionData?.data?.data
            setSectionList(data)
        } catch (err) {
            console.log("error on categoryList: ", err)
            // navigate("/")
        }
    }

    useEffect(() => {
        getSections(currentPage)
        getFontFamily()
        getSubCategoryList()
    }, [])

    useEffect(() => {
        if (subCategoryId?.value) {
            getFormTabList(null)
        } else if (searchSubCategoryId?.value) {
            getFormTabList("search")
        }
    }, [subCategoryId, searchSubCategoryId])

    // delete sub-Category
    const deleteApplicationTab = async (item) => {
        try {
            const formData = new FormData();
            formData.append("section_id", item?.id)
            await deleteApplicationSectionsTabData(formData)
            getSections(currentPage)
            setDeleteData("")
        } catch (error) {
            console.error(error);
        }
    }
    //edit section
    const editSubCategory = async (item) => {
        const list = await getFormTabSubCategoryListData({ 'category_id': item?.category_id })
        const data = await list?.data?.map(i => ({ label: i?.name_en, value: i?.id }))
        const tabName = data?.find(i => i?.value == item?.tab_id)
        setEditData(item)
        setSubCategoryId(subCategoryList?.find(i => i?.value == item?.category_id))
        setFormTabId(tabName)
        setNameEn(item?.name_en);
        setNameHi(item?.name_hi)
        setFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
        setFontColor(item?.font_color)
        setFontSize(item?.font_size)
        setBackgroundColor(item?.background_color)
        window.scrollTo(0, 0);
    }

    //update section
    const updateSubCategoryTab = async () => {
        if (!validateForm("both")) return
        try {
            const keyName = subCategoryId.label?.toLowerCase()?.replaceAll(" ", "_")
            const formData = new FormData();
            formData.append("section_id", editData?.id)
            formData.append("sub_category_id", subCategoryId.value)
            formData.append("tab_id", formTabId.value)
            formData.append("tab_key", keyName)
            formData.append("name_en", nameEn)
            formData.append("name_hi", nameHi)
            formData.append("sort", 10)
            formData.append("status", "1")
            formData.append("font_family_id", fontFamily?.value)
            formData.append("font_color", fontColor)
            formData.append("font_size", fontSize)
            formData.append("background_color", backgroundColor)
            const data = await updateSubCategoryTabSectionData(formData)
            if (data?.status == 200) {
                getSections(currentPage)
                setEditContainer(false)
                setNameEn("")
                setNameHi("")
                setSubCategoryId("")
                setFormTabId("")
                setFontFamily("");
                setFontColor("")
                setFontSize("")
                setBackgroundColor("")
                setEditData("")
                getFormTabList()
                setApiMessage({ type: 'success', message: "Section updated" });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: "error occured" });
        }
    }
    const handleEmptyData = () => {
        setNameEn("")
        setNameHi("")
        setSubCategoryId("")
        setFontFamily("");
        setFontColor("")
        setFormTabId("")
        setFormTabIdError("")
        setFontSize("")
        setBackgroundColor("")
        setNameEnError("")
        setNameHiError("")
        setSubCategoryIdError("")
        setFontFamilyError("");
        setFontColorError("")
        setFontSizeError("")
        setBackgroundColorError("")
        setEditData()
        setEditContainer(false)
    }
    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("section_id", item?.id)
            formData.append("status", e?.value)
            const data = await handleEnableSectionsDisableData(formData)
            if (data?.status == 200) {
                getSections(currentPage)
                setApiMessage({ type: 'success', message: data.message })
            } else if (data?.status == 401) {
                setApiMessage({ type: 'error', message: data.message });
            } else {
                setApiMessage({ type: 'error', message: data.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    }
    useEffect(() => {
        let data = [...sectionFullList]

        if (searchSubCategoryId) {
            data = data?.filter(item => item?.category_id == searchSubCategoryId?.value)
        }
        if (searchFormTabId) {
            data = data?.filter(item => item?.tab_id == searchFormTabId?.value)

        }
        setSectionList(data)

    }, [searchSubCategoryId, searchFormTabId])

    const resetFilter = () => {
        setSearchFormTabId("");
        setSearchSubCategoryId("");
        setSearchFormTabList([])
        setSectionList(sectionFullList)
    }
    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                {editContainer ? <>
                    <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                        <div>
                            <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b><span>Section Form</span>
                        </div>
                    </div>
                    <div className="aod-inner">
                        <div className={editData?.id ? "aod-update aod-bottom" : "aod-bottom"}>
                            <div className="aod-head text-center adm-head"> <button onClick={() => { setEditContainer(false); handleEmptyData() }}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} Section</div>
                            <div className="row py-4">
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='lf-label w-100'>
                                            Select Sub Category
                                        </label>
                                        <Select
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            isDisabled={editData?.id}
                                            value={subCategoryId}
                                            options={subCategoryList}
                                            onChange={(e) => { setSubCategoryId(e); setSubCategoryIdError("") }}
                                            placeholder="Select Sub Category"
                                            onMenuOpen={() => handleKeyUpLoginDetails("subCategoryId")}
                                            onMenuClose={() => handleKeyUpLoginDetails("subCategoryId")}
                                        />
                                        {subCategoryIdError && <p className='validate-error'>{subCategoryIdError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='lf-label w-100'>
                                            Select Form Tab
                                        </label>
                                        <Select
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            isDisabled={editData?.id}
                                            value={formTabId}
                                            options={formTabList}
                                            onChange={(e) => { setFormTabId(e); setFormTabIdError("") }}
                                            placeholder="Select Form Tab"
                                            onMenuOpen={() => handleKeyUpLoginDetails("formTabId")}
                                            onMenuClose={() => handleKeyUpLoginDetails("formTabId")}
                                        />
                                        {formTabIdError && <p className='validate-error'>{formTabIdError}</p>}
                                    </div>

                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            Section English Name
                                        </label>
                                        <input maxlength={60} className='w-100' placeholder='Enter Section English Name' type="text" name="nameEn" value={nameEn} onChange={handleInputChange} />

                                        {nameEnError && <p className='validate-error'>{nameEnError}</p>}

                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            Section Hindi Name
                                        </label>
                                        <input maxlength={60} className='w-100' placeholder='Enter Section Hindi Name' type="text" name="nameHi" value={nameHi} onChange={handleInputChange} />

                                        {nameHiError && <p className='validate-error'>{nameHiError}</p>}
                                    </div>
                                </div>

                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Family</label>
                                        <Select
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            value={fontFamily}
                                            options={fontsList}
                                            onChange={(e) => { setFontFamily(e); setFontFamilyError("") }}
                                            placeholder="Select Font Family"
                                            onMenuOpen={() => handleKeyUpLoginDetails("family")}
                                            onMenuClose={() => handleKeyUpLoginDetails("family")}
                                        />
                                        {fontFamilyError && <p className='validate-error'>{fontFamilyError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Color</label>
                                        <label className='adm-label-color'>
                                            {fontColor ? fontColor : <span>Select Font Color</span>}
                                            <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("color")} onBlur={() => handleKeyUpLoginDetails("color")} value={fontColor} onChange={(e) => setFontColor(e?.target?.value)} />
                                        </label>
                                        {fontColorError && <p className='validate-error'>{fontColorError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Size (in pixels)</label>
                                        <input className='w-100' placeholder='Enter Font Size' max="20" type="number" value={fontSize} onKeyUp={() => handleKeyUpLoginDetails("size")}
                                            onKeyDown={(e) => {
                                                if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={async (e) => {
                                                const value = e.target.value;
                                                if (value > 0) {
                                                    if (await checkPatternFontSize(value)) {
                                                        setFontSize(value);
                                                        setFontSizeError("")
                                                    }
                                                } else {
                                                    setFontSize("");
                                                }
                                            }} />
                                        {fontSizeError && <p className='validate-error'>{fontSizeError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Background Color</label>
                                        <label className='adm-label-color'>
                                            {backgroundColor ? backgroundColor : <span>Select Background Color</span>}
                                            <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("bgcolor")} onBlur={() => handleKeyUpLoginDetails("bgcolor")} value={backgroundColor} onChange={(e) => setBackgroundColor(e?.target?.value)} />
                                        </label>
                                        {backgroundColorError && <p className='validate-error'>{backgroundColorError}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="row ad-cat-row">
                                {
                                    editData?.id ?
                                        <>
                                            <div className="col-md-6 gap-4 d-flex">
                                                <button onClick={() => handleEmptyData()} className='suf-can-butt w-auto px-4'>
                                                    Cancel
                                                </button>
                                                <button onClick={() => updateSubCategoryTab()}>
                                                    Update Section
                                                </button> </div></> :
                                        <div className="col-md-3">
                                            <button onClick={addSubCategoryTab}>
                                                Add Section
                                            </button></div>
                                }
                            </div>

                        </div>
                    </div>
                </> :
                    <>
                        <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                            <div>
                                <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b><span> Application Section</span>
                            </div>
                            <button className='adm-tab-btn' onClick={() => setEditContainer(true)}><span><HiPlus />
                            </span> Add Section</button>
                        </div>
                        <div className="aod-inner pt-0">
                            <div className="aod-bottom">
                                <div className="adm-tabs-outer d-flex justify-content-between d-md-flex justify-content-between">
                                    <h4 className='mb-0 w-100'>Section List</h4>

                                    <input className='w-25 px-2 form-control' placeholder='search' type="text" onChange={(e) => searchSortFromList(e, 'id')} />
                                </div>
                                <div class="aod-head"></div>
                                <div className="row py-4">
                                    <div className="col-md-4 aod-resp-tab">
                                        <div className='suf-input-box aod-form-input mb-2'>
                                            <Select
                                                className='menu_open_select'
                                                classNamePrefix='ad-menu_open_select_class'
                                                aria-label="Default select example"
                                                value={searchSubCategoryId}
                                                options={subCategoryList}
                                                onChange={(e) => setSearchSubCategoryId(e)}
                                                placeholder="Select Sub Category"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 aod-resp-tab">
                                        <div className='suf-input-box aod-form-input mb-2'>
                                            <Select
                                                className='menu_open_select'
                                                classNamePrefix='ad-menu_open_select_class'
                                                aria-label="Default select example"
                                                isDisabled={editData?.id}
                                                value={searchFormTabId}
                                                options={searchFormTabList}
                                                onChange={(e) => setSearchFormTabId(e)}
                                                placeholder="Select Form Tab"
                                            />
                                        </div>

                                    </div>
                                    <div className="col-md-1">
                                        <button onClick={resetFilter} className='ad-reset-btn w-auto px-4'>
                                            Reset
                                        </button></div>

                                </div>
                                <div className="ad-reg-table">
                                    <Table size="sm" className='ad-cat-table'>
                                        <thead>
                                            <tr>
                                                <th className='ad-sno'>S No</th>
                                                <th className='ad-long'>English Name<span><i className="fa-solid fa-sort"></i></span></th>
                                                <th className='ad-long'>Hindi Name<span><i className="fa-solid fa-sort"></i></span></th>


                                                <th className='ad-long'>Status</th>
                                                <th className='ad-long'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sectionList?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item?.name_en}</td>
                                                        <td>{item?.name_hi}</td>

                                                        <td>
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={item?.status == 1}
                                                                    onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                                />
                                                                <span className="slider"></span>
                                                            </label>
                                                        </td>
                                                        <td><span className='ad-cat-edit' onClick={() => { editSubCategory(item); setEditContainer(true) }}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                    <div className="d-flex w-100 justify-content-end">
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={totalPages} // Total number of pages from API
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={1}
                                            onPageChange={handlePageChange} // Method to handle page click
                                            containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                }
            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this section ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2 d-flex flex-row">
                        <button className='mx-2' onClick={() => { deleteApplicationTab(deleteData) }}>Yes</button>
                        <button className='suf-can-butt mx-2' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </AdminDashboard>
    )
}

export default ApplicationSections
