import React, { useEffect } from 'react'
import DashCategory from '../../components/DashbaordCategory/DashCategory'
import Dashboard from '../Dashboard/Dashboard'
import { useLanguage } from '../../components/LanguageProvider/LanguageProvider '

const DashboardCategory = () => {
    const {language,setLanguage}=useLanguage();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div> 
            <Dashboard first={language ? "होम": "Home"} second={language ? "वर्ग":"Category"} head={language ? "वर्ग":"Category "} >
            <DashCategory />
        </Dashboard>

        </div>
    )
}

export default DashboardCategory
