import React, { useEffect, useRef, useState } from 'react';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import { addGuidelinesFieldsData, getGuidelinesFieldsData, getSubCategoriesDropdown, getSubCategoryListData, updateGuidelinesFieldsData } from '../../../services/api/admin';
import Select from 'react-select';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AdminGuildlines.css';
import { BsChevronLeft } from 'react-icons/bs';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import { GetLocalStorage } from '../../../Utils/Utils';
import { Message } from '../../../components/message';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ],
};

const formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

const AdminGuidelines = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const token = GetLocalStorage("userInfo")
  const [subCategoryId, setSubCategoryId] = useState();
  const [subCategoryIdError, setSubCategoryIdError] = useState();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [mandatoryGuidelines_en, setMandatoryGuidelines_en] = useState('');
  const [optionalGuidelines_en, setOptionalGuidelines_en] = useState('');
  const [mandatoryGuidelines_hi, setMandatoryGuidelines_hi] = useState('');
  const [optionalGuidelines_hi, setOptionalGuidelines_hi] = useState('');
  const [mandatoryGuidelinesEnErr, setMandatoryGuidelinesEnErr] = useState('')
  const [mandatoryGuidelinesHiErr, setMandatoryGuidelinesHiErr] = useState('')
  const [optionalGuidelinesHiErr, setOptionalGuidelinesHiErr] = useState('')
  const [optionalGuidelinesEnErr, setOptionalGuidelinesEnErr] = useState('')
  const [guidelinesId, setGuidelinesId] = useState(null)
  const [editIcon, setEditIcon] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const [editData, setEditData] = useState()
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });

  useEffect(() => {
    if (!token) {
      navigate("/")
    } else {
      if (location?.state && location?.pathname == "/admin/guidelines/update") {
        editCategory(location?.state)
        setEdit(true)
      }
    }
  }, [location?.state])

  useEffect(() => {
    const getSubCategoryList = async () => {
      try {
        const subCategoryData = await getSubCategoriesDropdown();
        if (subCategoryData && subCategoryData.status === 200) {
          const data = subCategoryData.data?.map(i => ({
            label: i?.category_name,
            value: i?.id
          }));
          setSubCategoryList(data);
        }
      } catch (error) {
        console.log("error on sub category list: ", error);
        setApiMessage({ type: 'error', message: "error occured while fectching sub category list" });
      }
    };
    getSubCategoryList();
  }, []);


  //edit category
  const editCategory = (item) => {
    setEditData(item)
    setSubCategoryId(item.category_id)
    setGuidelinesId(item.id)
    setMandatoryGuidelines_en(item.mandatory_guidelines_en)
    setMandatoryGuidelines_hi(item.mandatory_guidelines_hi)
    setOptionalGuidelines_en(item.optional_guidelines_en)
    setOptionalGuidelines_hi(item.optional_guidelines_hi)
    window.scrollTo(0, 0);
  }

  const updateFormField = async () => {
    if (!validateForm()) return;
    try {
      const editordata = {
        'mandatoryGuidelines_en': mandatoryGuidelines_en,
        'optionalGuidelines_en': optionalGuidelines_en,
        'mandatoryGuidelines_hi': mandatoryGuidelines_hi,
        'optionalGuidelines_hi': optionalGuidelines_hi
      }
      const formData = new FormData();
      formData.append("guideline_id", guidelinesId)
      formData.append("category_id", editData?.category_id);
      formData.append("mandatory_guidelines_en", editordata.mandatoryGuidelines_en);
      formData.append("optional_guidelines_en", editordata.optionalGuidelines_en);
      formData.append("mandatory_guidelines_hi", editordata.mandatoryGuidelines_hi);
      formData.append("optional_guidelines_hi", editordata.optionalGuidelines_hi);
      const updateGuidlines = await updateGuidelinesFieldsData(formData)
      if (updateGuidlines && updateGuidlines.status == 200) {

        setGuidelinesId()
        navigate("/admin/guidelines", { state: { message: "GuideLine updated" } })
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: 'error', message: "error occured" });
    }
  };

  const handleEditorChange = (field, value) => {
    // Update content
    if (field === 'mandatoryGuidelines_en') setMandatoryGuidelines_en(value);
    if (field === 'optionalGuidelines_en') setOptionalGuidelines_en(value);
    if (field === 'mandatoryGuidelines_hi') setMandatoryGuidelines_hi(value);
    if (field === 'optionalGuidelines_hi') setOptionalGuidelines_hi(value);
    if (field === 'mandatoryGuidelines_en' && mandatoryGuidelinesEnErr) setMandatoryGuidelinesEnErr('');
    if (field === 'optionalGuidelines_en' && optionalGuidelinesEnErr) setOptionalGuidelinesEnErr('');
    if (field === 'mandatoryGuidelines_hi' && mandatoryGuidelinesHiErr) setMandatoryGuidelinesHiErr('');
    if (field === 'optionalGuidelines_hi' && optionalGuidelinesHiErr) setOptionalGuidelinesHiErr('');
  };

  const validateForm = () => {
    let isValid = true
    if (!subCategoryId) {
      setSubCategoryIdError("Please Select Sub Category")
      isValid = false
    } else {
      setSubCategoryIdError("");
    }
    if (mandatoryGuidelines_en.replace(/(<([^>]+)>)/gi, '').trim() == '') {
      setMandatoryGuidelinesEnErr('Please enter Mandatory Guidelines')
      isValid = false
    } else {
      setMandatoryGuidelinesEnErr('')
    }
    if (mandatoryGuidelines_hi.replace(/(<([^>]+)>)/gi, '').trim() == '') {
      setMandatoryGuidelinesHiErr('Please enter Mandatory Guidelines')
      isValid = false
    } else {
      setMandatoryGuidelinesHiErr('')
    }
    if (optionalGuidelines_en.replace(/(<([^>]+)>)/gi, '').trim() == '') {
      setOptionalGuidelinesEnErr('Please enter optional Guidelines')

      isValid = false
    } else {

      setOptionalGuidelinesEnErr('')
    }
    if (optionalGuidelines_hi.replace(/(<([^>]+)>)/gi, '').trim() == '') {
      setOptionalGuidelinesHiErr('Please enter optional Guidelines')
      isValid = false
    } else {
      setOptionalGuidelinesHiErr('')
    }
    return isValid;
  }

  const addFormField = async () => {
    if (!validateForm()) return;
    const editordata = {
      'mandatoryGuidelines_en': mandatoryGuidelines_en,
      'optionalGuidelines_en': optionalGuidelines_en,
      'mandatoryGuidelines_hi': mandatoryGuidelines_hi,
      'optionalGuidelines_hi': optionalGuidelines_hi
    }

    try {
      const formData = new FormData();
      formData.append("category_id", subCategoryId);
      formData.append("mandatory_guidelines_en", editordata.mandatoryGuidelines_en);
      formData.append("optional_guidelines_en", editordata.optionalGuidelines_en);
      formData.append("mandatory_guidelines_hi", editordata.mandatoryGuidelines_hi);
      formData.append("optional_guidelines_hi", editordata.optionalGuidelines_hi);
      const addData = await addGuidelinesFieldsData(formData)
      if (addData?.status == 200) {

        setGuidelinesId()
        navigate("/admin/guidelines", { state: { message: "New GuideLine added" } })
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: 'error', message: error?.message });
    }
  };

  const handlesubcategoryChange = async (selectedOption) => {
    try {
      setSubCategoryId(selectedOption.value);
      setSubCategoryIdError("");
      const data = await getGuidelinesFieldsData()
      if (data?.status == 200) {
        const isExist = data?.data?.data?.find(subCategory => subCategory.category_id === selectedOption.value);
        if (isExist) {
          editCategory(isExist)
        } else {
          setMandatoryGuidelines_en('');
          setOptionalGuidelines_en('');
          setMandatoryGuidelines_hi('');
          setOptionalGuidelines_hi('');
        }
      } else {
        console.log("error")
        setApiMessage({ type: 'error', message: "error occured while fetching existing Guidelines" });
      }
    } catch (err) {
      setApiMessage({ type: 'error', message: "error occured while fetching existing Guidelines" });
    }
  }


  return (
    <AdminDashboard>
      <div className='aod-outer'>
        {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
          <div>
            <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b><span>Guidelines Form</span>
          </div>

        </div>
        <div className="aod-inner">
          <div className={edit ? "aod-update aod-bottom" : "aod-bottom"}>
            {/* <div className="aod-head"></div> */}
            <div className="aod-head text-center adm-head"> <button onClick={() => navigate("/admin/guidelines")}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} Guidelines</div>
            <div className="row py-4">
              <div className="col-md-5 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-5' >
                  <label className='lf-label w-100'>
                    Select Sub Category
                  </label>
                  <Select
                    className="menu_open_select"
                    classNamePrefix="ad-menu_open_select_class"
                    aria-label="Default select example"
                    // isDisabled={editData?.id}
                    value={subCategoryList?.find(option => option.value === subCategoryId) || null}
                    options={subCategoryList}
                    onChange={(selectedOption) => {
                      handlesubcategoryChange(selectedOption)
                    }}
                    placeholder="Select Sub Category"
                  />

                  {subCategoryIdError && <p className='validate-error'>{subCategoryIdError}</p>}
                </div>
              </div>
              <div className='d-flex gap-5  flex-column justify-content-between'>
                <div className='d-flex gap-3 justify-content-between'>
                  <div className='suf-input-box aod-form-input mb-5'>
                    <label className='lf-label w-100'>Mandatory Guidelines English</label>
                    <ReactQuill
                      name="mandatoryGuidelines_en"
                      value={mandatoryGuidelines_en}
                      onChange={(value) => handleEditorChange('mandatoryGuidelines_en', value)}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                    // readOnly={!editData?.id}
                    />
                    {mandatoryGuidelinesEnErr && <p className='validate-error'>{mandatoryGuidelinesEnErr}</p>}
                  </div>

                  <div className='suf-input-box aod-form-input mb-5'>
                    <label className='lf-label w-100'>Optional Guidelines English</label>
                    <ReactQuill
                      name="optionalGuidelines_en"
                      value={optionalGuidelines_en}
                      onChange={(value) => handleEditorChange('optionalGuidelines_en', value)}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                    // readOnly={!editData?.id}
                    />
                    {optionalGuidelinesEnErr && <p className='validate-error'>{optionalGuidelinesEnErr}</p>}
                  </div>
                </div>

                <div className='d-flex gap-3 justify-content-between'>
                  <div className='suf-input-box aod-form-input mb-5'>
                    <label className='lf-label w-100'>Mandatory Guidelines Hindi</label>
                    <ReactQuill
                      name="mandatoryGuidelines_hi"
                      value={mandatoryGuidelines_hi}
                      onChange={(value) => handleEditorChange('mandatoryGuidelines_hi', value)}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                    // readOnly={!editData?.id}
                    />
                    {mandatoryGuidelinesHiErr && <p className='validate-error'>{mandatoryGuidelinesHiErr}</p>}
                  </div>

                  <div className='suf-input-box aod-form-input mb-5'>
                    <label className='lf-label w-100'>Optional Guidelines Hindi</label>
                    <ReactQuill
                      name="optionalGuidelines_hi"
                      value={optionalGuidelines_hi}
                      onChange={(value) => handleEditorChange('optionalGuidelines_hi', value)}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                    // readOnly={!editData?.id}
                    />
                    {optionalGuidelinesHiErr && <p className='validate-error'>{optionalGuidelinesHiErr}</p>}
                  </div>
                </div>

              </div>
              <div className="row ad-cat-row">
                <div className="col-md-7 gap-4 d-flex">
                  <button onClick={() => navigate("/admin/guidelines")} className='suf-can-butt w-auto px-4'>
                    Cancel
                  </button>
                  {
                    editData?.id && guidelinesId ?
                      <button onClick={() => updateFormField()}>
                        Update Guidelines
                      </button> :
                      <button onClick={() => addFormField()}>
                        Save Guidelines
                      </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default AdminGuidelines;
