import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { HiPlus } from 'react-icons/hi'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import { Modal, Table } from 'react-bootstrap'
import { deleteRoleData, getEnableDisableRolesData, getRoleListDataSort, getRolesListData } from '../../../services/api/admin'
import ReactPaginate from 'react-paginate'
import Select from 'react-select';
import { DeleteIcon, EditIcon } from '../AdminIcon'
import sortByIcon from '../../../assets/images/sort.png';
import { Message } from '../../../components/message'
import { formatDateWithSlash } from '../../../Utils/common'

const RolesList = () => {
    const navigate = useNavigate();
    const [rolesList, setRolesList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [orderBy, setOrderBy] = useState("asc");
    const [deleteData, setDeleteData] = useState()
    const { state } = useLocation();
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });

    useEffect(() => {
        if (state && state.message) {
            setApiMessage({ type: 'success', message: state.message });
            // Clear the message once it is rendered
            window.history.replaceState({}, document.title);
        }
    }, [state])

    useEffect(() => {
        getRolesList(currentPage)
    }, [])

    const getRolesList = async (pageNo) => {
        try {
            const roleList = await getRolesListData(pageNo)
            const data = roleList?.data?.data
            setRolesList(data)
            setTotalPages(Math.ceil(roleList?.data?.total / roleList?.data?.per_page)); // Calculate total pages
        } catch (error) {
            console.log("error on tab list: ", error)
        }
    };

    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("role_id", item?.id)
            formData.append("status", e?.value)
            const data = await getEnableDisableRolesData(formData)
            if (data?.status == 200) {
                setApiMessage({ type: 'success', message: data.message });
                getRolesList(currentPage)
            }
        } catch (error) {
            setApiMessage({ type: 'error', message: error.message });
        }
    }

    const deleteRole = async (item) => {
        try {
            // const formData = new FormData();
            // formData.append("role_id", item?.id)
            const data = await deleteRoleData(item?.id)
            if (data.status == 200) {
                setDeleteData("")
                getRolesList(currentPage)
                setApiMessage({ type: 'success', message: data.message });
            } else {
                setDeleteData("")
            }
        } catch (error) {
            console.error(error);
            setDeleteData("")
            setApiMessage({ type: 'error', message: error.message });
        }
    }

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        getRolesList(selectedPage.selected + 1); // Increment by 1 if the API starts page numbers from 1
    };


    const searchSortFromList = async (e, sortBy) => {
        try {
            const searchBy = e.target.value ? e.target.value : '';
            setOrderBy(orderBy == 'asc' ? 'desc' : 'asc')
            const tabList = await getRoleListDataSort(sortBy, orderBy, searchBy)
            const data = tabList?.data?.data
            setRolesList(data)
        } catch (err) {
            console.log("error on categoryList: ", err)
            // navigate("/")
        }
    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                    <div>
                        <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span>Roles </span>

                    </div>
                    <button className='adm-tab-btn' onClick={() => navigate("/admin/roles/add")}><span><HiPlus />
                    </span> Add Roles</button>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="adm-tabs-outer d-flex justify-content-between">
                            <h4 className='mb-0'>Roles List </h4>   <input className='w-25 px-2 form-control' placeholder='search' type="text" onChange={(e) => searchSortFromList(e, 'title')} />
                        </div>
                        <div className="aod-head"></div>
                        <div className="ad-reg-table">
                            <Table size="sm" className='ad-cat-table mt-4'>
                                <thead>
                                    <tr>
                                        <th className='ad-sno'>S No</th>
                                        <th className='ad-sno'>Name<span><img src={sortByIcon} alt="" height={20} onClick={(e) => searchSortFromList(e, 'title')} /></span></th>
                                        <th className='ad-sno'>Created At<span><img src={sortByIcon} alt="" height={20} onClick={(e) => searchSortFromList(e, 'created_at')} /></span></th>
                                        <th className='ad-long'>status</th>
                                        <th className='ad-long'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rolesList?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item?.title ? item?.title : '--'}</td>
                                                <td>{item?.created_at ? formatDateWithSlash(item?.created_at) : '--'}</td>
                                                <td>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={item?.status == 1}
                                                            onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                </td>
                                                <td><span className='ad-cat-edit' onClick={() => navigate("/admin/roles/update", { state: item })}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span>
                                                    <span onClick={() => {
                                                        navigate("/admin/permissions/edit", { state: item })
                                                        // editPermission(item)
                                                    }}>Edit Permission</span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            <div className="d-flex w-100 justify-content-end">
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={totalPages} // Total number of pages from API
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={1}
                                    onPageChange={handlePageChange} // Method to handle page click
                                    containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this Tab ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2 d-flex flex-row">
                        <button className='mx-2' onClick={() => { deleteRole(deleteData) }}>Yes</button>
                        <button className='suf-can-butt mx-2' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal>

        </AdminDashboard>
    )
}

export default RolesList
