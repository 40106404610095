/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { UploadIcon } from '../AdminIcon'
import { Apiurl, GetLocalStorage } from '../../../Utils/Utils'
import axios from 'axios'
import Select from 'react-select';
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useLocation, useNavigate } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'
import { addCategoryData, getCategoriesDropdown, getFontFamilyData, updateSubCategoryData } from '../../../services/api/admin'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { Message } from '../../../components/message'
import { checkPattern, checkPatternFontSize } from '../../../Utils/common'

const SubCategoryAdd = () => {

  const token = GetLocalStorage("userInfo")
  const navigate = useNavigate()
  const location = useLocation()

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState();

  const [nameHi, setNameHi] = useState('');
  const [nameHiError, setNameHiError] = useState();

  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState();

  const [image, setImage] = useState();
  const [imageError, setImageError] = useState();

  const [fontFamily, setFontFamily] = useState();
  const [fontFamilyError, setFontFamilyError] = useState();

  const [fontColor, setFontColor] = useState();
  const [fontColorError, setFontColorError] = useState();

  const [fontSize, setFontSize] = useState('');
  const [fontSizeError, setFontSizeError] = useState();

  const [backgroundColor, setBackgroundColor] = useState();
  const [backgroundColorError, setBackgroundColorError] = useState();
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [categoryId, setCategoryId] = useState()
  const [categoryIdError, setCategoryIdError] = useState()

  const [categoryList, setCategoryList] = useState([])
  const [editData, setEditData] = useState()
  const [fontsList, setFontsList] = useState()
  useEffect(() => {
    if (location?.state && location?.pathname == "/admin/sub-category/update") {
      editSubCategory(location?.state)
    }
  }, [location?.state, categoryList])
  const handleKeyUpLoginDetails = (item) => {
    validateForm(item);
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const pattern = /^[a-zA-Z\s]*$/;

    // Check for "nameEn" field
    if (name === "nameEn") {

      if (await checkPattern(value)) {
        setName(value);
      } else {
        e.preventDefault()
        return;
      }
    }

    // Check for "nameHi" field
    if (name === "nameHi") {
      if (await checkPattern(value)) {
        setNameHi(value);
      } else {
        e.preventDefault()
        return;
      }
    }

    // Check for "desc" field
    if (name === "desc") {
      if (await checkPattern(value)) {
        setDescription(value);  // Update state if valid input
      } else {
        e.preventDefault()
        return;
      }
    }
  };



  //validations
  const validateForm = (item) => {
    let isValid = true;
    if (item === "category" || item === "both") {
      if (!categoryId) {
        setCategoryIdError("Please Select Category");
        isValid = false;
      } else {
        setCategoryIdError("");
      }
    }

    if (item === "name" || item === "both") {
      if (!name || name.trim() === "") {
        setNameError("Please Enter English Name");
        isValid = false;
      } else {
        setNameError("");
      }
    }
    if (item === "nameHi" || item === "both") {
      if (!nameHi || nameHi.trim() === "") {
        setNameHiError("Please Enter Hindi Name");
        isValid = false;
      } else {
        setNameHiError("");
      }
    }
    if (item === "desc" || item === "both") {
      if (!description || description.trim() === "") {
        setDescriptionError("Please Enter Description");
        isValid = false;
      } else {
        setDescriptionError("");
      }
    }

    if (item === "img" || item === "both") {
      if (!image) {
        setImageError("Please Upload Image");
        isValid = false;
      } else {
        setImageError("");
      }
    }

    if (item === "family" || item === "both") {
      if (!fontFamily) {
        setFontFamilyError("Please Select Font Family");
        isValid = false;
      } else {
        setFontFamilyError("");
      }
    }

    if (item === "color" || item === "both") {
      if (!fontColor || fontColor.trim() === "") {
        setFontColorError("Please Select Font Color");
        isValid = false;
      } else {
        setFontColorError("");
      }
    }

    if (item === "size" || item === "both") {
      if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
        setFontSizeError("Please Enter Valid Font Size");
        isValid = false;
      } else {
        setFontSizeError("");
      }
    }

    if (item === "bgcolor" || item === "both") {
      if (!backgroundColor || backgroundColor.trim() === "") {
        setBackgroundColorError("Please Select Background Color");
        isValid = false;
      } else if (backgroundColor == fontColor) {
        setBackgroundColorError("Font color and background color cannot be same.");
        isValid = false;
      } else {
        setBackgroundColorError("");
      }
    }

    return isValid;
  };
  // add sub-Category
  const addSubCategory = async () => {
    if (!validateForm("both")) return
    try {
      const keyName = name?.toLowerCase()?.replaceAll(" ", "_")
      const formData = new FormData();
      formData.append("category_id", categoryId.value)
      formData.append("category_name", name)
      formData.append("category_key", keyName)
      formData.append("category_name_hi", nameHi)
      formData.append("category_description", description)
      formData.append("category_image", image)
      formData.append("font_family_id", fontFamily?.value)
      formData.append("font_color", fontColor)
      formData.append("font_size", fontSize)
      formData.append("background_color", backgroundColor)

      await addCategoryData(formData)
      navigate("/admin/sub-category", { state: { message: "category added" } })
    } catch (error) {
      console.error(error);
      setApiMessage({ type: 'error', message: "error occured" });
    }
  }
  //category list
  const getCategoryList = async () => {
    try {
      const categoryList = await getCategoriesDropdown()
      const data = categoryList?.data?.filter(i => i?.status === "1")?.map(item => ({
        value: item?.id,
        label: item?.category_name
      }))
      setCategoryList(data)
    } catch (err) {
      console.log("error on sub Ctaegory list: ", err)
      // navigate("/")
    }
  };

  //get font families
  const getFontFamily = async () => {
    try {
      const fontFamilyData = await getFontFamilyData()
      const data = fontFamilyData?.data?.map(i => ({
        label: i?.name, value: i?.id
      }))

      setFontsList(data)
    } catch (err) {
      console.log("font families error :", err)
    }
  };

  useEffect(() => {
    getFontFamily()
    getCategoryList()
  }, [])

  //edit sub-category
  const editSubCategory = (item) => {
    setEditData(item)
    setName(item?.category_name);
    setNameHi(item?.category_name_hi);


    setDescription(item?.category_description)
    setImage(item?.category_image)
    setCategoryId(categoryList?.find(i => i.value == item?.parent_category_id))
    setFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
    setFontColor(item?.font_color)
    setFontSize(item?.font_size)
    setBackgroundColor(item?.background_color)
    window.scrollTo(0, 0);
  }
  //update sub-category
  const updateSubCategory = async () => {
    if (!validateForm("both")) return
    try {
      const keyName = name?.toLowerCase()?.replaceAll(" ", "_")

      const formData = new FormData();
      formData.append("parent_category_id", editData?.parent_category_id)
      formData.append("category_id", editData?.id)
      formData.append("category_key", keyName)
      formData.append("category_name", name)
      formData.append("category_name_hi", nameHi)
      formData.append("category_description", description)
      formData.append("category_image", image)
      formData.append("font_family_id", fontFamily?.value)
      formData.append("font_color", fontColor)
      formData.append("font_size", fontSize)
      formData.append("background_color", backgroundColor)
      await updateSubCategoryData(formData)
      navigate("/admin/sub-category", { state: { message: "category added" } })
    } catch (error) {
      console.error(error);
      setApiMessage({ type: 'error', message: "error occured" });
    }
  }

  const handleFileChange = (e) => {
    const file = e?.target?.files?.[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setImage(file);
        setImageError('');
      } else {
        setImage(null);
        setImageError('Please upload an image file.');
        e.target.value = '';
      }
    }
  };


  return (
    <AdminDashboard>
      <div className='aod-outer'>
        {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
          <div>
            <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>  <span>Sub Category Form</span>
          </div>
        </div>
        <div className="aod-inner">
          <div className={editData?.id ? "aod-bottom" : "aod-bottom"}>
            <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/sub-category")}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} Sub Category</div>
            <div className="row py-4">
              <div className="col-md-6 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='lf-label w-100'>
                    Select Category
                  </label>
                  <Select
                    className='menu_open_select'
                    classNamePrefix='ad-menu_open_select_class'
                    aria-label="Default select example"
                    value={categoryId}
                    options={categoryList}
                    onChange={(e) => { setCategoryId(e); setCategoryIdError("") }}
                    placeholder="Select Category"
                    onMenuOpen={() => handleKeyUpLoginDetails("category")}
                    onMenuClose={() => handleKeyUpLoginDetails("category")}
                  />
                  {categoryIdError && <p className='validate-error'>{categoryIdError}</p>}
                </div>
              </div>
              <div className="col-md-6 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>

                  <label className='mb-1 w-100'>
                    Sub Category English Name
                  </label>
                  <input className='w-100' placeholder='Enter Category English Name' type="text" name="nameEn" value={name} onChange={handleInputChange} />
                  {nameError && <p className='validate-error'>{nameError}</p>}

                </div>
              </div>
              <div className="col-md-6 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>

                  <label className='mb-1 w-100'>
                    Sub Category Hindi Name
                  </label>
                  <input className='w-100' placeholder='Enter Category Hindi Name' type="text" name="nameHi" value={nameHi} onChange={handleInputChange} />
                  {nameHiError && <p className='validate-error'>{nameHiError}</p>}

                </div>
              </div>
              <div className="col-md-6 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>

                  <label className='mb-1 w-100'>
                    Sub Category Description
                  </label>
                  <input className='w-100' placeholder='Enter Category Description' type="text" name="desc" value={description} onChange={handleInputChange} />

                  {descriptionError && <p className='validate-error'>{descriptionError}</p>}

                </div>
              </div>
              <div className="col-md-6 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>
                    Sub Category Image
                  </label>
                  <input type="file" name="partsuploadfile" id="profileimg" className="w-100 ad-cat-input-field-image" accept="image/*" onChange={(e) => handleFileChange(e)} />
                  <label htmlFor="profileimg" className="ad-cat-input-upload-image p-0">
                    <p className="ad-cat-text-upload-image">
                      <span><UploadIcon /></span> Choose Category Image
                    </p>
                  </label>
                  {/* {image?.name ? <p className='validate-error'>{image?.name}</p> : imageError ? <p className='validate-error'>{imageError}</p> : ""} */}
                  {
                    typeof image === 'string' ? (
                      <p className="validate-error">{image}</p>
                    ) : (
                      image?.name ? (
                        <p className="validate-error">{image?.name}</p>
                      ) : (
                        imageError ? <p className="validate-error">{imageError}</p> : ""
                      )
                    )
                  }

                </div>
              </div>
              <div className="col-md-6 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>Font Family</label>
                  <Select
                    className='menu_open_select'
                    classNamePrefix='ad-menu_open_select_class'
                    aria-label="Default select example"
                    value={fontFamily}
                    options={fontsList}
                    onChange={(e) => { setFontFamily(e); setFontFamilyError(""); }}
                    placeholder="Select Font Family"
                    onMenuOpen={() => handleKeyUpLoginDetails("family")}
                    onMenuClose={() => handleKeyUpLoginDetails("family")}
                  />
                  {fontFamilyError && <p className='validate-error'>{fontFamilyError}</p>}
                </div>
              </div>
              <div className="col-md-6 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>Font Color</label>
                  <label className='adm-label-color'>
                    {fontColor ? fontColor : <span>Select Font Color</span>}
                    <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("color")} onBlur={() => handleKeyUpLoginDetails("color")} value={fontColor} onChange={(e) => setFontColor(e?.target?.value)} />
                  </label>
                  {fontColorError && <p className='validate-error'>{fontColorError}</p>}
                </div>
              </div>
              <div className="col-md-6 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>Font Size (in pixels)</label>
                  <input className='w-100' max={99} placeholder='Enter Font Size' type="number" value={fontSize} onKeyUp={() => handleKeyUpLoginDetails("size")}
                    onKeyDown={(e) => {
                      if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                        e.preventDefault();
                      }
                    }}
                    onChange={async (e) => {
                      const value = e.target.value;
                      if (value > 0) {
                        if (await checkPatternFontSize(value)) {
                          setFontSize(value);
                          setFontSizeError("")
                        }
                      } else {
                        setFontSize("");

                      }
                    }} />
                  {fontSizeError && <p className='validate-error'>{fontSizeError}</p>}
                </div>
              </div>
              <div className="col-md-6 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>Background Color</label>
                  <label className='adm-label-color'>
                    {backgroundColor ? backgroundColor : <span>Select Background Color</span>}
                    <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("bgcolor")} onBlur={() => handleKeyUpLoginDetails("bgcolor")} value={backgroundColor} onChange={(e) => setBackgroundColor(e?.target?.value)} />
                  </label>
                  {backgroundColorError && <p className='validate-error'>{backgroundColorError}</p>}
                </div>
              </div>

            </div>
            <div className="row ad-cat-row">
              {
                editData?.id ?
                  <>
                    <div className="w-auto gap-4 d-flex">
                      <button onClick={() => updateSubCategory()}>
                        Update
                      </button> </div></> :
                  <div className="w-auto">
                    <button onClick={addSubCategory}>
                      Submit
                    </button></div>
              }
            </div>

          </div>
        </div>
      </div>
    </AdminDashboard>
  )
}

export default SubCategoryAdd
