/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Apiurl, GetLocalStorage, ImageurlIcons } from '../../../Utils/Utils';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { EditIcon } from '../AdminIcon';
import { getResetPasswordFieldData } from '../../../services/api/admin';

const AdminResetPassword = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate()
    const token = GetLocalStorage("userInfo")
    const [loginData, setLoginData] = useState([])
    const [activeTab, setActiveTab] = useState(1)

    const [buttData, setButtData] = useState([]);


    //login fields list
    const getLoginFields = async () => {
        try {
            const ResetPasswordField = await getResetPasswordFieldData()
            const data = ResetPasswordField?.data
            setLoginData(data)
            setButtData([...ResetPasswordField?.data?.button_fields, ...ResetPasswordField?.data?.form_fields]);
        } catch (err) {
            console.log("erron on reset password field: ", err)
        }
    };

    useEffect(() => {
        getLoginFields()
    }, [])


    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-4'>
                    <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b><span>Reset Password Form</span>
                </div>
                {/* Input Fields Table */}
                <div className="aod-inner">
                    <div className={"aod-bottom"}>
                        <div className="adm-tabs-outer">
                            <button className={activeTab == 1 ? "active-adm-tab btn-first" : "inactive-adm-tab btn-first"} onClick={() => setActiveTab(1)}>Reset Password Form Field List </button>
                            <button className={activeTab == 2 ? "active-adm-tab btn-last" : "inactive-adm-tab btn-last"} onClick={() => setActiveTab(2)}>Reset Password Form Buttons </button>
                        </div>
                        <div className="aod-head"></div>
                        <div className="ad-reg-table">
                            {
                                activeTab == 1 ? <Table size="sm" className='ad-cat-table mt-4'>
                                    <thead>
                                        <tr>
                                            <th className='ad-sno'>S No</th>
                                            <th className='ad-long'>Icon</th>
                                            <th className='ad-long'>English Name</th>
                                            <th className='ad-long'>Hindi Name</th>
                                            <th className='ad-long'>Placeholder English Name</th>
                                            <th className='ad-long'>Placeholder Hindi Name</th>
                                            <th className='ad-sno'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loginData?.input_fields?.map((item, index) => (
                                                <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.icon ? <img className='ad-cat-td-img' src={`${ImageurlIcons()}/${item?.icon}`} alt="" /> : "--"}</td>
                                                    <td>{item?.name_en ? item?.name_en : "--"}</td>
                                                    <td>{item?.name_hi ? item?.name_hi : "--"}</td>
                                                    <td>{item?.placeholder_en ? item?.placeholder_en : "--"}</td>
                                                    <td>{item?.placeholder_hi ? item?.placeholder_hi : "--"}</td>
                                                    <td><span className='ad-cat-edit' onClick={() => navigate("/admin/reset-password/update", { state: item })}><EditIcon /></span></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table> : activeTab == 2 ? <Table size="sm" className='ad-cat-table mt-4'>
                                    <thead>
                                        <tr>
                                            <th className='ad-sno'>S No</th>
                                            <th className='ad-long'>English Name</th>
                                            <th className='ad-long'>Hindi Name</th>
                                            <th className='ad-long'>Type</th>
                                            <th className='ad-long'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            buttData?.map((item, index) => (
                                                <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.name_en ? item?.name_en : "--"}</td>
                                                    <td>{item?.name_hi ? item?.name_hi : "--"}</td>
                                                    <td>{item?.type ? item?.type : "--"}</td>
                                                    <td><span className='ad-cat-edit' onClick={() => navigate("/admin/reset-password/update-buttons", { state: item })}><EditIcon /></span></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table> : null
                            }

                        </div>

                    </div>
                </div>
                {/* Input Fields Table End */}
            </div>
        </AdminDashboard>
    )
}

export default AdminResetPassword