/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import { Apiurl, GetLocalStorage, Imageurl } from '../../../Utils/Utils'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useLocation, useNavigate } from 'react-router-dom'
import { HiPlus } from 'react-icons/hi'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { deleteCategoryData, getCategoriesDropdown, getSubCategoryListData, getSubCategoryListDataSort, handleEnablecategoryDisableData } from '../../../services/api/admin';
import sortByIcon from '../../../assets/images/sort.png';
import ReactPaginate from 'react-paginate'
import Select from 'react-select';
import { Message } from '../../../components/message'

const SubCategory = () => {
  const token = GetLocalStorage("userInfo")
  const navigate = useNavigate()
  const [categoryList, setCategoryList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [deleteData, setDeleteData] = useState()
  const [orderBy, setOrderBy] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { state } = useLocation();
  useEffect(() => {
    if (state && state.message) {
      setApiMessage({ type: 'success', message: state.message });
      // Clear the message once it is rendered
      window.history.replaceState({}, document.title);
    }
  }, [state])
  //category list
  const getCategoryList = async () => {
    try {
      const categoryList = await getCategoriesDropdown()
      const data = categoryList?.data?.map(item => ({
        value: item?.id,
        label: item?.category_name
      }))
      if (categoryList?.status == 200) {
        setCategoryList(data)
      }
    } catch (err) {
      console.log("error on sub Ctaegory list: ", err)
      setApiMessage({ type: 'error', message: err?.message });

    }
  };

  // sub category list
  const getSubCategoryList = async (pageNo) => {
    try {
      const subCategoryList = await getSubCategoryListData(pageNo)
      const data = subCategoryList?.data?.data
      if (subCategoryList?.status == 200) {
        setSubCategoryList(data)
        setTotalPages(Math.ceil(subCategoryList?.data?.total / subCategoryList?.data?.per_page)); // Calculate total pages
      } else {
        setApiMessage({ type: 'error', message: subCategoryList?.message });
      }
    } catch (err) {
      console.log("Error on subCategorylist: ", err)
      setApiMessage({ type: 'error', message: err?.message });

    }
  };

  //common function for search, sort of list
  const searchSortFromList = async (e, sortBy) => {
    try {
      const searchBy = e.target.value ? e.target.value : '';
      setOrderBy(orderBy == 'asc' ? 'desc' : 'asc')
      const subCategoryList = await getSubCategoryListDataSort(sortBy, orderBy, searchBy)
      if (subCategoryList?.status == 200) {
        const data = subCategoryList?.data?.data
        setSubCategoryList(data)
      } else {
        setApiMessage({ type: 'error', message: subCategoryList?.message });
      }
    } catch (err) {
      console.log("Error on subCategorylist: ", err)
      setApiMessage({ type: 'error', message: "error occured" });

    }
  }

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    getSubCategoryList(selectedPage.selected + 1); // Increment by 1 if the API starts page numbers from 1
  };

  //get font families
  useEffect(() => {
    getCategoryList()
    getSubCategoryList(currentPage)
  }, [])

  // delete sub-Category
  const deleteSubCategory = async (item) => {
    try {
      const formData = new FormData();
      formData.append("parent_category_id", item?.parent_category_id)
      formData.append("category_id", item?.id)
      await deleteCategoryData(formData)
      getSubCategoryList(currentPage)
      setDeleteData("")
    } catch (error) {
      console.error(error);
    }
  }

  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("category_id", item?.id)
      formData.append("status", e?.value)
      const data = await handleEnablecategoryDisableData(formData)   // api same for cat and sub cat
      if (data?.status == 200) {
        getSubCategoryList(currentPage)
        setApiMessage({ type: 'success', message: data?.message });
      } else if (data?.status == 401) {
        setApiMessage({ type: 'error', message: data?.message });
      } else {
        setApiMessage({ type: 'error', message: data?.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: 'error', message: error?.message });
    }
  }

  return (
    <AdminDashboard>
      <div className='aod-outer'>
        {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
          <div>
            <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>  <span>Sub Category</span>
          </div>
          <button className='adm-tab-btn' onClick={() => navigate("/admin/sub-category/add")}><span><HiPlus />
          </span> Add Sub Category</button>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer d-flex justify-content-between">
              <h4 className='mb-0'>Sub Category List</h4>     <input className='w-25 px-2w-25 px-2 form-control' placeholder='search' type="text" onChange={(e) => searchSortFromList(e, 'category_name')} />
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className='ad-cat-table mt-4'>
                <thead>
                  <tr>
                    <th className='ad-sno'>S No</th>
                    <th className='ad-long'>Category Name <span><i class="fa-solid fa-sort" onClick={(e) => searchSortFromList(e, 'parent_category_id')}></i></span></th>
                    <th className='ad-long'>Image</th>
                    <th className='ad-long'>English Name <span><i class="fa-solid fa-sort" onClick={(e) => searchSortFromList(e, 'category_name')}></i></span></th>
                    <th className='ad-long'>Hindi Name <span><i class="fa-solid fa-sort" onClick={(e) => searchSortFromList(e, 'category_name')}></i></span></th>
                    <th className='ad-long'>Description <span><i class="fa-solid fa-sort" onClick={(e) => searchSortFromList(e, 'category_description')}></i></span></th>
                    <th className='ad-long'>status</th>
                    <th className='ad-long'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    subCategoryList?.map((item, index) => (
                      <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                        <td>{index + 1}</td>
                        {/* <td>{item?.parent_category_id} </td> */}
                        <td>{categoryList?.find(i => i.value == item?.parent_category_id)?.label}</td>
                        <td>{item?.category_image ? <img className='ad-cat-td-img sub-cat-img' src={`${Imageurl()}/${item?.category_image}`} alt="" /> : "--"}</td>
                        <td>{item?.category_name ? item?.category_name : "--"}</td>
                        <td>{item?.category_name_hi ? item?.category_name_hi : "--"}</td>
                        <td>{item?.category_description ? item?.category_description : "--"}</td>
                        {/* <td> <Select
                          className='menu_open_select'
                          classNamePrefix='ad-menu_open_select_class'
                          aria-label="Default select example"
                          value={{ label: item?.status == 1 ? "Active" : "Inactive", value: item?.status }}
                          options={[{ label: "Active", value: "1" }, { label: "Inactive", value: "0" }]}
                          onChange={(e) => handleEnableDisable(e, item)}
                          placeholder="Select Status"
                        /></td> */}
                        <td>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={item?.status == 1}
                              onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                            />
                            <span className="slider"></span>
                          </label>
                        </td>
                        <td><span className='ad-cat-edit' onClick={() => { navigate("/admin/sub-category/update", { state: item }) }}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span></td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <div className="d-flex w-100 justify-content-end">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={totalPages} // Total number of pages from API
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageChange} // Method to handle page click
                  containerClassName={"pagination justify-content-center"} // Custom CSS classes
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>Do you really want to delete sub this Category ?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body></Modal.Body> */}
        <Modal.Footer>
          <div className="suf-btn p-2 d-flex flex-row">
            <button className='mx-2' onClick={() => { deleteSubCategory(deleteData) }}>Yes</button>
            <button className='suf-can-butt mx-2' onClick={() => setDeleteData("")}>No</button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  )
}

export default SubCategory
