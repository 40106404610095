import React, { useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom';
import { Message } from '../../../components/message';

const SmsSettingPage = () => {
    const navigate = useNavigate();
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                    <div>
                        <b onClick={() => navigate("/admin/")}>Home  /</b><span>Category Form</span>
                    </div>
                </div>
                <div className="aod-inner">

                </div>
            </div>

        </AdminDashboard>
    )
}

export default SmsSettingPage
