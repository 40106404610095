import React, { useState, useEffect } from 'react';
import "./GuidelinesComponent.css";
import { CheckIcon, CircleIcon, MandatoryDocumentIcon } from './GuidelineIcon';
import { useNavigate } from 'react-router-dom';
import { GetLocalStorage, GetLocalStorageParsed } from '../../Utils/Utils';
import { getGuideLines } from '../../services/api/outer';
import { useLanguage } from '../LanguageProvider/LanguageProvider ';

const GuidelinesComponent = () => {
    const getLoginSubCabData = GetLocalStorage("LoginSubCatData");
    const navigate = useNavigate();
    const { language } = useLanguage();

    const [guidelines, setGuidelines] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleNavigate = () => {
        if (getLoginSubCabData) {
            navigate("/application/organization-details");
        }
    }

    useEffect(() => {
        const fetchGuidelines = async () => {
            try {
                const getLoginSubCabData = GetLocalStorageParsed("LoginSubCatData");
                if (getLoginSubCabData) {
                    const payload = {
                        category_id: getLoginSubCabData.id
                    };
                    const response = await getGuideLines(payload);
                    console.log("response", response);
                    setGuidelines(response);
                }
            } catch (error) {
                setError("Failed to load guidelines");
                console.error("API error:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchGuidelines();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="gc-outline">
            <div className="gc-apply-butt">
                <button onClick={handleNavigate}>Apply Now</button>
            </div>
            <div className="gc-inner">
                <div className="gc-col">
                    <div className="gc-head">
                        <MandatoryDocumentIcon /> Mandatory Documents
                    </div>
                    <div className='gc-content'>
                        <span dangerouslySetInnerHTML={{ __html: language ? guidelines?.mandatory_guidelines_hi : guidelines?.mandatory_guidelines_en }}></span>
                    </div>
                </div>
                <div className="gc-col">
                    <div className="gc-head">
                        <MandatoryDocumentIcon /> Optional Documents
                    </div>
                    <div className='gc-content-bottom'>
                        <span dangerouslySetInnerHTML={{ __html: language ? guidelines?.optional_guidelines_hi : guidelines?.optional_guidelines_en }}></span>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default GuidelinesComponent;
