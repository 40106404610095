/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import { Apiurl, GetLocalStorage, RemoveSession } from '../../../Utils/Utils'
import axios from 'axios'
import Select from 'react-select';
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom'
import { addFormFieldData, deleteFormFieldData, getFontFamilyData, getFormFieldListDataSort, getFormFieldsListData, getFormTabSubCategoryListData, getSectionListDataByTabId, getSubCategoriesDropdown, getSubCategoryListData, handleEnableDisableFormFieldsData, updateFormFieldData } from '../../../services/api/admin'
import ReactPaginate from 'react-paginate';
import sortByIcon from '../../../assets/images/sort.png';
import { BsChevronLeft } from 'react-icons/bs'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { HiPlus } from 'react-icons/hi'
import { Message } from '../../../components/message'
import { checkPatternWithSpecialCharacter, checkPatternFontSize, checkPattern } from '../../../Utils/common'
const FormFields = () => {
    const navigate = useNavigate();
    const token = GetLocalStorage("userInfo")
    const [enName, setEnName] = useState('');
    const [enNameError, setEnNameError] = useState();
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [hnName, setHnName] = useState('');
    const [hnNameError, setHnNameError] = useState();

    const [enPlaceholder, setEnPlaceholder] = useState('');
    const [enPlaceholderError, setEnPlaceholderError] = useState();

    const [hnPlaceholder, setHnPlaceholder] = useState('');
    const [hnPlaceholderError, setHnPlaceholderError] = useState();


    // const [inputName, setInputName] = useState();
    // const [inputNameError, setInputNameError] = useState();

    const [inputType, setInputType] = useState();
    const [inputTypeError, setInputTypeError] = useState();

    // const [dropdownTypeOptionList, setDropdownTypeOptionList] = useState()
    const [dropdownTypeOptionListErr, setDropdownTypeOptionListErr] = useState('')
    const [dropdownTypeOption, setDropdownTypeOption] = useState()


    const [fontFamily, setFontFamily] = useState();
    const [fontFamilyError, setFontFamilyError] = useState();

    const [fontColor, setFontColor] = useState();
    const [fontColorError, setFontColorError] = useState();

    const [bgColor, setBgColor] = useState();
    const [bgColorError, setBgColorError] = useState();

    const [fontSize, setFontSize] = useState('');
    const [fontSizeError, setFontSizeError] = useState();

    const [inputRequired, setInputRequired] = useState()
    const [inputRequiredError, setInputRequiredError] = useState()

    const [dropdownValues, setDropDownValues] = useState([{ value: '' }])
    const [dropdownValuesError, setDropDownValuesError] = useState()

    const [validation, setValidation] = useState([{ value: '' }])
    const [subCategoryId, setSubCategoryId] = useState()
    const [searchSubCategoryId, setSearchSubCategoryId] = useState()
    const [subCategoryIdError, setSubCategoryIdError] = useState()
    const [formTabId, setFormTabId] = useState()
    const [searchFormTabId, setSearchFormTabId] = useState()

    const [formTabIdError, setFormTabIdError] = useState()
    const [sectionId, setSectionId] = useState()
    const [searchSectionId, setSearchSectionId] = useState()
    const [sectionIdError, setSectionIdError] = useState()
    const [formFields, setFormFields] = useState([])
    const [formFieldsFull, setFormFieldsFull] = useState([])
    const [fontsList, setFontsList] = useState()
    const [sectionList, setSectionList] = useState([])
    const [editData, setEditData] = useState()
    const [subCategoryList, setSubCategoryList] = useState([])
    const [formTabList, setFormTabList] = useState()
    const [searchFormTabList, setSearchFormTabList] = useState()
    const [searchSectionList, setSearchSectionList] = useState()
    const [deleteData, setDeleteData] = useState()
    const [editContainer, setEditContainer] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [orderBy, setOrderBy] = useState("asc");

    const handleInputChange = async (e) => {
        const { name, value } = e.target;

        // Check for "enName" field
        if (name === "enName") {

            if (await checkPatternWithSpecialCharacter(value)) {
                setEnName(value);
            } else {
                e.preventDefault()
                return;
            }
        }

        // Check for "hnName" field
        if (name === "hnName") {
            if (await checkPatternWithSpecialCharacter(value)) {
                setHnName(value);
            } else {
                e.preventDefault()
                return;
            }
        }

        // Check for "enPlaceholder" field
        if (name === "enPlaceholder") {

            if (await checkPatternWithSpecialCharacter(value)) {
                setEnPlaceholder(value);
            } else {
                e.preventDefault()
                return;
            }
        }

        // Check for "hnPlaceholder" field
        if (name === "hnPlaceholder") {
            if (await checkPattern(value)) {
                setHnPlaceholder(value);
            } else {
                e.preventDefault()
                return;
            }
        }
    }

    const handleKeyUpLoginDetails = (item) => {
        validateForm(item);
    };
    //validations
    const validateForm = (item) => {
        let isValid = true;
        // console.log("skhkhsskd", item)
        // console.log("formTsectionIdddddddd", item === "both")
        if (item === "subCategoryId" || item === "both" || item === "all") {

            if (!subCategoryId) {
                setSubCategoryIdError("Please Select Sub Category");
                isValid = false;
            } else {
                setSubCategoryIdError("");
            }
        }
        if (item === "formTabId" || item === "both" || item === "all") {

            // if (!subCategoryId) {
            //     setSubCategoryIdError("Please Select Sub Category");
            //     isValid = false;
            // }
            if (!formTabId) {
                setFormTabIdError("Please Select Form Tab");
                isValid = false;
            } else {
                setFormTabIdError("");
            }
        }
        if (item === "sectionId" || item === "both" || item === "all") {

            // if (!formTabId) {
            //     setFormTabIdError("Please Select Form Tab");
            //     isValid = false;
            // }
            if (!sectionId) {
                setSectionIdError("Please Select Section");
                isValid = false;
            } else {
                setSectionIdError("");
            }
        }
        if (item === "enName" || item === "all") {
            // if (!sectionId) {
            //     setSectionIdError("Please Select Section");
            //     isValid = false;
            // }
            if (!enName || enName.trim() === "") {
                setEnNameError("Please Enter English Name");
                isValid = false;
            } else {
                setEnNameError("");
            }
        }

        if (item === "hnName" || item === "all") {
            // if (!enName || enName.trim() === "") {
            //     setEnNameError("Please Enter English Name");
            //     isValid = false;
            // }
            if (!hnName || hnName.trim() === "") {
                setHnNameError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setHnNameError("");
            }
        }

        if (item === "enPlaceholder" || item === "all") {
            if (!enPlaceholder || enPlaceholder.trim() === "") {
                setEnPlaceholderError("Please Enter English Placeholder");
                isValid = false;
            } else {
                setEnPlaceholderError("");
            }
        }

        if (item === "hnPlaceholder" || item === "all") {
            if (!hnPlaceholder || hnPlaceholder.trim() === "") {
                setHnPlaceholderError("Please Enter Hindi Placeholder");
                isValid = false;
            } else {
                setHnPlaceholderError("");
            }
        }

        // if (item === "inputName" || item === "all") {
        //     const inputNameRegex = /^[a-z_]+$/;
        //     if (!hnName || hnName.trim() === "") {
        //         setHnNameError("Please Enter Hindi Name");
        //         isValid = false;
        //     }
        //     if (!inputName || inputName.trim() === "") {
        //         setInputNameError("Please Enter Input Name");
        //         isValid = false;
        //     } else if (!inputNameRegex.test(inputName)) {
        //         setInputNameError("Input Name must contain only lowercase letters and underscores");
        //         isValid = false;
        //     } else {
        //         setInputNameError("");
        //     }
        // }

        if (item === "fontFamily" || item === "all") {
            // if (!inputName || inputName.trim() === "") {
            //     setInputNameError("Please Enter Input Name");
            //     isValid = false;
            // }
            // if (!hnName || hnName.trim() === "") {
            //     setHnNameError("Please Enter Hindi Name");
            //     isValid = false;
            // }
            if (!fontFamily) {
                setFontFamilyError("Please Select Font Family");
                isValid = false;
            } else {
                setFontFamilyError("");
            }
        }

        if (item === "fontColor" || item === "all") {
            // if (!fontFamily) {
            //     setFontFamilyError("Please Select Font Family");
            //     isValid = false;
            // }
            if (!fontColor || fontColor.trim() === "") {
                setFontColorError("Please Select Font Color");
                isValid = false;
            } else {
                setFontColorError("");
            }
        }

        if (item === "bgColor" || item === "all") {
            // if (!fontFamily) {
            //     setFontFamilyError("Please Select Font Family");
            //     isValid = false;
            // }
            if (!bgColor || bgColor.trim() === "") {
                setBgColorError("Please Select Background Color");
                isValid = false;
            } else {
                setBgColorError("");
            }
        }

        if (item === "fontSize" || item === "all") {
            // if (!fontColor || fontColor.trim() === "") {
            //     setFontColorError("Please Select Font Color");
            //     isValid = false;
            // }
            if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
                setFontSizeError("Please Enter Valid Font Size");
                isValid = false;
            } else {
                setFontSizeError("");
            }
        }
        if (item === "inputType" || item === "all") {
            if (!inputType) {
                setInputTypeError("Please Select Input Type");
                isValid = false;
            } else {
                setInputTypeError("");
            }
        }
        if (item === "dropdown" || item === "all") {
            if (!dropdownTypeOption?.value) {
                setDropdownTypeOptionListErr("Please Select DropDown Option")
            } else {
                setDropdownTypeOptionListErr("")
            }
        }
        if (item === "required" || item === "all") {
            // if (!inputType) {
            //     setInputTypeError("Please Select Input Type");
            //     isValid = false;
            // }
            if (inputType?.value == "dropdown" && dropdownValues?.length < 2) {
                setDropDownValuesError("Drop down must contain atleast two values")
            }
            if (!inputRequired) {
                setInputRequiredError("Please select Status");
                isValid = false;
            }
            else {
                setInputRequiredError("");
            }
        }


        return isValid;
    };
    useEffect(() => {
        if (inputType?.value == "dropdown" && dropdownValues?.length >= 2) {
            setDropDownValuesError("")
        }
    }, [dropdownValues])
    // add Form field
    const addFormField = async () => {
        if (!validateForm("all")) return
        try {
            const formData = new FormData();
            const inputName = enName?.toLowerCase()?.replaceAll(" ", "_")
            // const dropdata = JSON.stringify({ [inputName]: dropdownValues?.map(i => i?.value) });
            const validData = JSON.stringify({ validation: JSON.stringify(validation.map(i => i?.value)) })
            formData.append("sub_category_id", subCategoryId?.value)
            formData.append("tab_id", formTabId?.value)
            formData.append("section_id", sectionId?.value)
            formData.append("name_en", enName)
            formData.append("name_hi", hnName)
            formData.append("placeholder_en", enPlaceholder)
            formData.append("placeholder_hi", enPlaceholder)
            // formData.append("dropdown_values", dropdata ? dropdata : null)
            formData.append("field_key", inputName)
            formData.append("font_family_id", Number(fontFamily?.value))
            formData.append("font_color", fontColor)
            formData.append("font_size", Number(fontSize))
            formData.append("type", inputType?.value)
            formData.append("type_value", dropdownTypeOption?.value)
            formData.append("status", 1)
            formData.append("sort", "1")
            formData.append("background_color", bgColor)
            formData.append("validations", validData)
            formData.append("required", inputRequired?.value)
            const data = await addFormFieldData(formData)
            if (data?.status == 200) {
                handleEmptyData()
                getFormFieldsList(currentPage)
                setEditContainer(false)
                setApiMessage({ type: 'success', message: "Form Field added" });
            } else {
                handleEmptyData()
                setEditContainer(false)
                console.log(data);
                setApiMessage({ type: 'error', message: "error occured" });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: "error occured" });
        }
    }
    // sub category list
    const getSubCategoryList = async () => {
        try {
            const subCategoryData = await getSubCategoriesDropdown()
            const data = subCategoryData?.data?.map(i => ({
                label: i?.category_name, value: i?.id
            }))

            setSubCategoryList(data)

        } catch (error) {
            console.log("error on sub category list: ", error)
            // navigate("/")
        }
    };
    // tab list
    const getFormTabList = async (item) => {
        const formData = new FormData();
        if (item == "search") {
            formData.append("category_id", searchSubCategoryId.value)
        } else {
            formData.append("category_id", subCategoryId.value)
        }
        await getFormTabSubCategoryListData(formData)
            .then((e) => {
                const data = e?.data?.map(i => ({
                    label: i?.name_en, value: i?.id
                }))
                if (item == "search") {
                    setSearchFormTabList(data)
                } else {
                    setFormTabList(data)
                }

            })
            .catch((error) => {
                setApiMessage({ type: 'error', message: "error occured" });
            });
    };
    // section list
    const getSectionList = async (item) => {
        const formData = new FormData();
        if (item == "search") {
            formData.append("tab_id", searchFormTabId.value)
        } else {
            formData.append("tab_id", formTabId.value)
        }
        await getSectionListDataByTabId(formData)
            .then((e) => {
                const data = e?.data?.map(i => ({
                    label: i?.name_en, value: i?.id
                }))
                if (item == "search") {
                    setSearchSectionList(data)
                } else {

                    setSectionList(data)

                }
                if (editData?.id) {
                    setSectionId(data?.find(i => i?.value == editData?.section_id))
                }

            })
            .catch((error) => {
                setApiMessage({ type: 'error', message: "error occured" });
            });
    };
    //Form fields list
    const getFormFieldsList = async (pageNo) => {
        try {
            const formFieldsData = await getFormFieldsListData(pageNo)
            const data = formFieldsData?.data?.data?.filter(i => i?.deleted_at == null)
            if (data?.length > 0) {
                setFormFields(data)
                setFormFieldsFull(data)
                setTotalPages(Math.ceil(formFieldsData?.data?.total / formFieldsData?.data?.per_page)); // Calculate total pages
            }
        } catch (error) {
            setApiMessage({ type: 'error', message: "error occured" });
        }
    };
    //get font families
    const getFontFamily = async () => {
        try {
            const fontFamilyData = await getFontFamilyData()
            const data = fontFamilyData?.data?.map(i => ({
                label: i?.name, value: i?.id
            }))

            setFontsList(data)
        } catch (err) {
            console.log("font families error :", err)
            setApiMessage({ type: 'error', message: "error occured" });
        }
    };

    useEffect(() => {
        if (subCategoryId?.value) {
            getFormTabList(null)
        } else if (searchSubCategoryId?.value) {
            getFormTabList("search")
        }
    }, [subCategoryId, searchSubCategoryId])

    useEffect(() => {
        if (formTabId?.value) {
            getSectionList()
        } else if (searchFormTabId?.value) {
            setSearchSectionId("")
            getSectionList("search")
        }
    }, [formTabId, searchFormTabId])

    useEffect(() => {
        getFontFamily()
        getSubCategoryList()
        getFormFieldsList(currentPage)
    }, [])

    //edit sub-category
    const editFormField = async (item) => {
        setEditData(item)
        const list = await getFormTabSubCategoryListData({ 'category_id': item?.category_id })
        const data = await list?.data?.map(i => ({ label: i?.name_en, value: i?.id }))
        const tabName = data?.find(i => i?.value == item?.tab_id)
        const validations = item?.validations ? JSON.parse(item?.validations) : null;
        const parsedValidations = validations ? JSON.parse(validations.validation) : null;
        const valid = parsedValidations ? parsedValidations.map((val) => ({ value: val })) : null;
        setSubCategoryId(subCategoryList?.find(i => i?.value == item?.category_id))
        setFormTabId(tabName)
        setSectionId(sectionList?.find(i => i?.value == item?.section_id))
        setEnName(item?.name_en)
        setHnName(item?.name_hi)
        setEnPlaceholder(item?.placeholder_en)
        setHnPlaceholder(item?.placeholder_hi)
        setInputType(inputTypeList?.find(i => i.value == item?.type))
        setFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
        setFontColor(item?.font_color)
        setBgColor(item?.background_color)
        setFontSize(item?.font_size)
        setDropdownTypeOption(item.type_value)
        const dropdownValues = item?.dropdown_values ? JSON.parse(item?.dropdown_values) : null;
        const drop = dropdownValues && dropdownValues[item?.input_field_name] && Array.isArray(dropdownValues[item?.input_field_name])
            ? dropdownValues[item?.input_field_name].map(i => ({ value: i }))
            : "";
        setValidation(valid ? valid : [{ value: '' }])
        setDropDownValues(drop ? drop : [{ value: '' }])
        setInputRequired(requiredList?.find(i => i?.value == item?.required))
        window.scrollTo(0, 0);
    }

    //update sub-category
    const updateFormField = async () => {
        if (!validateForm("all")) return
        try {

            const formData = new FormData();
            formData.append("sub_category_id", subCategoryId?.value)
            formData.append("tab_id", formTabId?.value)
            formData.append("section_id", sectionId?.value)
            formData.append("field_id", editData?.id)
            formData.append("name_en", enName)
            formData.append("name_hi", hnName)
            formData.append("placeholder_en", enPlaceholder)
            formData.append("placeholder_hi", enPlaceholder)
            const inputName = enName?.toLowerCase()?.replaceAll(" ", "_")
            formData.append("field_key", inputName)
            formData.append("font_family_id", Number(fontFamily?.value))
            formData.append("font_color", fontColor)
            formData.append("font_size", Number(fontSize))
            formData.append("type", inputType?.value)
            formData.append("type_value", dropdownTypeOption?.value)
            formData.append("status", 1)
            formData.append("sort", "10")
            formData.append("background_color", bgColor)
            const dropdata = JSON.stringify({ [inputName]: dropdownValues?.map(i => i?.value) });
            formData.append("dropdown_values", dropdata ? dropdata : null)
            const validData = JSON.stringify({ validation: JSON.stringify(validation.map(i => i?.value)) })
            formData.append("validations", validData)
            formData.append("required", inputRequired?.value)
            const data = await updateFormFieldData(formData)
            if (data?.status == 200) {
                handleEmptyData()
                getFormFieldsList(currentPage)
                setEditContainer(false)
                setApiMessage({ type: 'success', message: "Form Field Updated" });
            }
            handleEmptyData()
            getFormFieldsList(currentPage)
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: "error occured" });
        }
    }

    const dropdownOptionList = [
        { label: "State", value: "0" },
        { label: "City", value: "1" },
        { label: "District", value: "2" },
        // { label: "Add new", value: "3" },
    ]

    const inputTypeList = [
        { label: "Text", value: 1 },
        { label: "Radio", value: 4 },
        { label: "Password", value: 7 },
        { label: "File", value: 5 },
        { label: "Dropdown", value: 2 },
        { label: "Sub Section Text", value: 6 },
        { label: "Checkbox", value: 3 },
    ]
    const statusList = [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
    ]
    const requiredList = [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
    ]
    const handleDropdownChange = (index, event) => {
        const values = [...dropdownValues];
        values[index].value = event.target.value;
        setDropDownValues(values);
    };

    const handleDropDownAddInput = () => {
        const values = [...dropdownValues];
        values.push({ value: '' });
        setDropDownValues(values);
    };

    const handleDropDownRemoveInput = (index) => {
        const values = [...dropdownValues];
        values.splice(index, 1);
        setDropDownValues(values);
    };
    const handleValidationChange = (index, event) => {
        const values = [...validation];
        values[index].value = event.target.value;
        setValidation(values);
    };

    const handleValidationAddInput = () => {
        if (validation.length < 5) {
            const values = [...validation];
            values.push({ value: '' });
            setValidation(values);
        }
    };

    const handleValidationRemoveInput = (index) => {
        const values = [...validation];
        values.splice(index, 1);
        setValidation(values);
    };
    const handleEmptyData = () => {
        setSearchFormTabId("");
        setSearchSubCategoryId("");
        setSearchFormTabList([])
        setSearchSectionId("")
        setSearchSectionList([])
        setSubCategoryId('')
        setSubCategoryIdError('')
        setFormTabId('')
        setFormTabIdError('')
        setSectionId()
        setSectionIdError('')
        setEnName("")
        setHnName("")
        setEnPlaceholder("")
        setHnPlaceholder("")
        setInputType("")
        setFontFamily("");
        setBgColor("")
        setFontColor("")
        setFontSize("")
        setValidation([{ value: '' }])
        setDropDownValues([{ value: '' }])
        setInputRequired('')
        setEditData()
        setEnPlaceholderError("")
        setHnPlaceholderError("")
        setInputTypeError("")
        setFontFamilyError("");
        setFontColorError("")
        setBgColorError("")
        setFontSizeError("")
        setDropDownValuesError("")
        setInputRequiredError("")
    }
    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("field_id", item?.id)
            // formData.append("section_id", item?.section_id)
            formData.append("status", e?.value)
            const data = await handleEnableDisableFormFieldsData(formData)
            if (data?.status == 200) {
                getFormFieldsList(currentPage)
                setApiMessage({ type: 'success', message: data?.message })
            } else if (data?.status == 401) {
                setApiMessage({ type: 'error', message: data?.message });
            } else {
                setApiMessage({ type: 'error', message: data?.message });
            }

        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    }

    useEffect(() => {
        let data = [...formFieldsFull]

        if (searchSubCategoryId?.value) {
            data = data?.filter(item => item?.category_id == searchSubCategoryId?.value)
            console.log(data)
        }
        if (searchFormTabId?.value) {
            data = data?.filter(item => item?.tab_id == searchFormTabId?.value)
            console.log(data)
        }
        if (searchSectionId?.value) {
            data = data?.filter(item => item?.section_id == searchSectionId?.value)
            console.log(data)
        }
        setFormFields(data)

    }, [searchSubCategoryId, searchFormTabId, searchSectionId])

    const resetFilter = () => {
        setSearchFormTabId("");
        setSearchSubCategoryId("");
        setSearchFormTabList([])
        setSearchSectionId("")
        setSearchSectionList([])
        setFormFields(formFieldsFull)
    }

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        getFormFieldsList(selectedPage.selected + 1); // Increment by 1 if the API starts page numbers from 1
    };


    const searchSortFromList = async (e, sortBy) => {
        try {
            const searchBy = e.target.value ? e.target.value : '';
            setOrderBy(orderBy == 'asc' ? 'desc' : 'asc')
            const formFieldList = await getFormFieldListDataSort(sortBy, orderBy, searchBy)
            const data = formFieldList?.data?.data?.filter(i => i?.status !== "0")
            setFormFields(data)
        } catch (err) {
            console.log("error on categoryList: ", err)
            // navigate("/")
        }
    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                {editContainer ? <>
                    <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                        <div>
                            <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span>FormField Form</span>

                        </div>
                    </div>
                    <div className="aod-inner">
                        <div className={editData?.id ? "aod-update aod-bottom" : "aod-bottom"}>
                            {/* <div className="aod-head">{editData?.id ? "Update" : "Add"} Form Field</div> */}
                            <div className="aod-head text-center adm-head"> <button onClick={() => setEditContainer(false)}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} Form</div>
                            <div className="row py-4">
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='lf-label w-100'>
                                            Select Sub Category
                                        </label>
                                        <Select
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            isDisabled={editData?.id}
                                            value={subCategoryId}
                                            options={subCategoryList}
                                            onChange={(e) => { setSubCategoryId(e); setSubCategoryIdError("") }}
                                            placeholder="Select Sub Category"
                                            onMenuOpen={() => handleKeyUpLoginDetails("subCategoryId")}
                                            onMenuClose={() => handleKeyUpLoginDetails("subCategoryId")}
                                        />
                                        {subCategoryIdError && <p className='validate-error'>{subCategoryIdError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='lf-label w-100'>
                                            Select Form Tab
                                        </label>
                                        <Select
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            isDisabled={editData?.id}
                                            value={formTabId}
                                            options={formTabList}
                                            onChange={(e) => { setFormTabId(e); setFormTabIdError("") }}
                                            placeholder="Select Form Tab"
                                            onMenuOpen={() => handleKeyUpLoginDetails("formTabId")}
                                            onMenuClose={() => handleKeyUpLoginDetails("formTabId")}
                                        />
                                        {formTabIdError && <p className='validate-error'>{formTabIdError}</p>}
                                    </div>

                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='lf-label w-100'>
                                            Select Section
                                        </label>
                                        <Select
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            isDisabled={editData?.id}
                                            value={sectionId}
                                            options={sectionList}
                                            onChange={(e) => { setSectionId(e); setSectionIdError("") }}
                                            placeholder="Select Section"
                                            onMenuOpen={() => handleKeyUpLoginDetails("sectionId")}
                                            onMenuClose={() => handleKeyUpLoginDetails("sectionId")}
                                        />
                                        {sectionIdError && <p className='validate-error'>{sectionIdError}</p>}
                                    </div>

                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>English Name</label>
                                        <input className='w-100' placeholder='Enter English Name' type="text" name="enName" value={enName} onChange={handleInputChange} />
                                        {enNameError && <p className='validate-error'>{enNameError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Hindi Name</label>
                                        <input className='w-100' placeholder='Enter Hindi Name' type="text" name="hnName" value={hnName} onChange={handleInputChange} />
                                        {hnNameError && <p className='validate-error'>{hnNameError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>English PlaceHolder</label>
                                        <input className='w-100' placeholder='Enter English PlaceHolder' type="text" name="enPlaceholder" value={enPlaceholder} onChange={handleInputChange} />
                                        {enPlaceholderError && <p className='validate-error'>{enPlaceholderError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Hindi PlaceHolder</label>
                                        <input className='w-100' placeholder='Enter Hindi PlaceHolder' type="text" name="hnPlaceholder" value={hnPlaceholder} onChange={handleInputChange} />
                                        {hnPlaceholderError && <p className='validate-error'>{hnPlaceholderError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Family</label>
                                        <Select
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            value={fontFamily}
                                            options={fontsList}
                                            onChange={(e) => { setFontFamily(e); setFontFamilyError("") }}
                                            placeholder="Select Font Family"
                                            onMenuOpen={() => handleKeyUpLoginDetails("fontFamily")}
                                            onMenuClose={() => handleKeyUpLoginDetails("fontFamily")}
                                        />
                                        {fontFamilyError && <p className='validate-error'>{fontFamilyError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Color</label>
                                        <label className='adm-label-color'>
                                            {fontColor ? fontColor : <span>Select Font Color</span>}
                                            <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("color")} onBlur={() => handleKeyUpLoginDetails("color")} value={fontColor} onChange={(e) => setFontColor(e?.target?.value)} />
                                        </label>
                                        {fontColorError && <p className='validate-error'>{fontColorError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Background Color</label>
                                        <label className='adm-label-color'>
                                            {bgColor ? bgColor : <span>Select Background Color</span>}
                                            <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("bgColor")} onBlur={() => handleKeyUpLoginDetails("bgColor")} value={bgColor} onChange={(e) => setBgColor(e?.target?.value)} />
                                        </label>
                                        {bgColorError && <p className='validate-error'>{bgColorError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Size (in pixels)</label>
                                        <input className='w-100' max={99} placeholder='Enter Font Size' type="number" value={fontSize} onKeyUp={() => handleKeyUpLoginDetails("size")}
                                            onKeyDown={(e) => {
                                                if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={async (e) => {
                                                const value = e.target.value;
                                                if (value > 0) {
                                                    if (await checkPatternFontSize(value)) {
                                                        setFontSize(value);
                                                        setFontSizeError("")
                                                    }
                                                } else {
                                                    setFontSize("");
                                                }
                                            }} />
                                        {fontSizeError && <p className='validate-error'>{fontSizeError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Input Type</label>
                                        <Select
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            value={inputType}
                                            options={inputTypeList}
                                            onChange={(e) => { setInputType(e); setInputTypeError(""); setDropdownTypeOptionListErr(""); setDropdownTypeOption() }}
                                            placeholder="Select Input Type"
                                            onMenuOpen={() => handleKeyUpLoginDetails("inputType")}
                                            onMenuClose={() => handleKeyUpLoginDetails("inputType")}
                                        />
                                        {inputTypeError && <p className='validate-error'>{inputTypeError}</p>}
                                    </div>
                                </div>
                                {
                                    inputType?.label == "Dropdown" ? (
                                        <div className="col-md-4 aod-resp-tab">
                                            <div className='suf-input-box aod-form-input mb-2'>
                                                <label className='mb-1 w-100'> DropDown Option Type</label>
                                                <Select
                                                    className='menu_open_select'
                                                    classNamePrefix='ad-menu_open_select_class'
                                                    aria-label="Default select example"
                                                    value={dropdownTypeOption}
                                                    options={dropdownOptionList}
                                                    onChange={(e) => { setDropdownTypeOption(e); setDropdownTypeOptionListErr("") }}
                                                    placeholder="Select DropDown Option"
                                                    onMenuOpen={() => handleKeyUpLoginDetails("dropdown")}
                                                    onMenuClose={() => handleKeyUpLoginDetails("dropdown")}
                                                />
                                                {dropdownTypeOptionListErr && <p className='validate-error'>{dropdownTypeOptionListErr}</p>}
                                            </div>
                                        </div>
                                    ) : (
                                        null
                                    )
                                }
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1'>Input Required</label>
                                        <Select
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            value={inputRequired}
                                            options={requiredList}
                                            onChange={(e) => { setInputRequired(e); setInputRequiredError("") }}
                                            placeholder="Select Input Required"
                                            onMenuOpen={() => handleKeyUpLoginDetails("required")}
                                            onMenuClose={() => handleKeyUpLoginDetails("required")}
                                        />
                                        {inputRequiredError && <p className='validate-error'>{inputRequiredError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Validation Description</label>
                                        {validation.map((input, index) => (
                                            <div className='row ad-reg-res' key={index}>
                                                <div className="col-md-9">
                                                    <div className='suf-input-box aod-form-input mb-2'>
                                                        <input
                                                            className='w-100'
                                                            placeholder={`Enter validation ${index + 1}`}
                                                            type="text"
                                                            value={input.value}
                                                            onChange={(event) => handleValidationChange(index, event)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-md-3 p-0 ad-reg-btn'>
                                                    {validation.length > 1 && (
                                                        <span className='ad-reg-delete' onClick={() => handleValidationRemoveInput(index)}>
                                                            <DeleteIcon />
                                                        </span>
                                                    )}
                                                    {validation.length === index + 1 && validation.length < 5 && (
                                                        <span className='ad-reg-plus' onClick={handleValidationAddInput}>+</span>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="row ad-cat-row">
                                {
                                    editData?.id ?
                                        <>
                                            <div className="col-md-7 gap-4 d-flex">
                                                <button onClick={() => handleEmptyData()} className='suf-can-butt w-auto px-4'>
                                                    Cancel
                                                </button>
                                                <button onClick={() => updateFormField()}>
                                                    Update Form Field
                                                </button> </div></> :
                                        <div className="col-md-4">
                                            <button onClick={() => { addFormField(); }}>
                                                Add Form Field
                                            </button></div>
                                }

                            </div>

                        </div>
                    </div>
                </> :
                    <>
                        <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                            <div>
                                <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span>Form Field</span>

                            </div>
                            <button className='adm-tab-btn' onClick={() => setEditContainer(true)}><span><HiPlus />
                            </span> Add Form Tab</button>
                        </div>
                        <div className="aod-inner pt-0">
                            <div className="aod-bottom">

                                <div className="adm-tabs-outer d-flex justify-content-between d-md-flex justify-content-between">
                                    <h4 className='mb-0 w-100'>Form Field List</h4>
                                    <input className='w-25 form-control' placeholder='search' type="text" onChange={(e) => searchSortFromList(e, 'name_en')} />

                                </div>
                                <div class="aod-head"></div>
                                <div className="row py-4">
                                    <div className="col-md-3">
                                        <div className='suf-input-box aod-form-input mb-2'>
                                            <Select
                                                className='menu_open_select'
                                                classNamePrefix='ad-menu_open_select_class'
                                                aria-label="Default select example"
                                                value={searchSubCategoryId}
                                                options={subCategoryList}
                                                onChange={(e) => { setSearchSubCategoryId(e); setSearchFormTabId(''); setSearchSectionId('') }}
                                                placeholder="Select Sub Category"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='suf-input-box aod-form-input mb-2'>
                                            <Select
                                                className='menu_open_select'
                                                classNamePrefix='ad-menu_open_select_class'
                                                aria-label="Default select example"
                                                value={searchFormTabId}
                                                options={searchFormTabList}
                                                onChange={(e) => { setSearchFormTabId(e); setSearchSectionId('') }}
                                                placeholder="Select Form Tab"
                                            />
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <div className='suf-input-box aod-form-input mb-2'>
                                            <Select
                                                className='menu_open_select'
                                                classNamePrefix='ad-menu_open_select_class'
                                                aria-label="Default select example"
                                                value={searchSectionId}
                                                options={searchSectionList}
                                                onChange={(e) => setSearchSectionId(e)}
                                                placeholder="Select Section"
                                            />
                                        </div>

                                    </div>
                                    <div className="col-md-1">
                                        <button onClick={resetFilter} className='ad-reset-btn w-auto px-4'>
                                            Reset
                                        </button>
                                    </div>
                                </div>

                                <div className="ad-reg-table">
                                    <Table size="sm" className='ad-cat-table table-striped'>
                                        <thead>
                                            <tr>
                                                <th className='ad-sno'>S No</th>
                                                <th className='ad-long'>English Name<span><i class="fa-solid fa-sort" onClick={(e) => searchSortFromList(e, 'name_en')}></i></span></th>
                                                <th className='ad-long'>Hindi Name<span><i class="fa-solid fa-sort" onClick={(e) => searchSortFromList(e, 'name_hi')}></i></span></th>
                                                <th className='ad-long'>English PlaceHolder <span><i class="fa-solid fa-sort" onClick={(e) => searchSortFromList(e, 'placeholder_en')}></i></span></th>
                                                <th className='ad-long'>Hindi PlaceHolder <span><i class="fa-solid fa-sort" onClick={(e) => searchSortFromList(e, 'placeholder_hi')}></i></span></th>
                                                <th className='ad-long'>Status</th>
                                                <th className='ad-long'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                formFields?.map((item, index) => {
                                                    const validations = item?.validations ? JSON.parse(item?.validations) : null;
                                                    const valid = validations && validations?.validation
                                                        ? Array.isArray(JSON.parse(validations.validation))
                                                            ? JSON.parse(validations.validation).join(", ")
                                                            : ""
                                                        : "";
                                                    const dropdownValues = item?.dropdown_values ? JSON.parse(item?.dropdown_values) : null;
                                                    const drop = dropdownValues && dropdownValues[item?.field_key] && Array.isArray(dropdownValues[item?.field_key])
                                                        ? dropdownValues[item?.field_key].map(i => i).join(",")
                                                        : "";
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item?.name_en ? item?.name_en : "--"}</td>
                                                            <td>{item?.name_hi ? item?.name_hi : "--"}</td>
                                                            <td>{item?.placeholder_en ? item?.placeholder_en : "--"}</td>
                                                            <td>{item?.placeholder_hi ? item?.placeholder_hi : "--"}</td>
                                                            <td>
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={item?.status == 1}
                                                                        onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                                    />
                                                                    <span className="slider"></span>
                                                                </label>
                                                            </td>
                                                            <td><span className='ad-cat-edit' onClick={() => { editFormField(item); setEditContainer(true) }}><EditIcon /></span></td>
                                                        </tr>
                                                    )
                                                }
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                    <div className="d-flex w-100 justify-content-end">
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={totalPages} // Total number of pages from API
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageChange} // Method to handle page click
                                            containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            {/* <span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this Field ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2">
                        <button onClick={() => { deleteFormField(deleteData) }}>Yes</button>
                        <button className='suf-can-butt' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal> */}
        </AdminDashboard >
    )
}

export default FormFields
