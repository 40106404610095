// API base url
export const Apiurl = () => {
  return "https://utdbttstg.dilonline.in/api";
};
// Image url
export const Imageurl = () => {
  return "https://utdbttstg.dilonline.in/backend/images/categories";
};
// Image url
export const ImageurlIcons = () => {
  return "https://utdbttstg.dilonline.in/backend/images/icons";
};
export const Baseurl = () => {
  return "https://utdbttstg.dilonline.in";
};
// get data from ls
export const GetLocalStorage = (item) => {
  return localStorage.getItem(item) || null;
};
// get and parse data from ls
export const GetLocalStorageParsed = (item) => {
  return JSON.parse(localStorage.getItem(item)) || null;
};
// set data to ls
export const SetLocalStorage = (item, value) => {
  localStorage.setItem(item, value);
};
// set stringify data to ls
export const SetLocalStorageStringify = (item, value) => {
  localStorage.setItem(item, JSON.stringify(value));
};
// remove from ls
export const RemoveLocalStorage = (item) => {
  localStorage.removeItem(item);
};
// to parse data
export const parseData = (item) => {
  return JSON.parse(item)
}
// to stringify data
export const stringifyData = (item) => {
  return JSON.stringify(item)
}
// clear ls
export const ClearLocalStorage = (item) => {
  localStorage.clear();
};
// remove user session
export const RemoveSession = (navigate) => {
  localStorage.clear();
  navigate('/')
};
export const validateonChangeHandle = (list, value, dataList, setDataList) => {

  const data = JSON.parse(list?.validations)
  const eee = JSON.parse(data?.validation)?.onChange
  for (let item of eee) {
    let newValue = value.target.value;
    if (newValue.startsWith(' ')) {
      newValue = newValue.trimStart();
    }
    // if (item?.type == "numeric" && item?.unit == "Required") {

    // }
    // else if (item?.type == "numeric" && item?.unit == "Less than") { 

    // }
    // else if (item?.type == "numeric" && item?.unit == "Greater than") { 

    // }
    // else if (item?.type == "numeric" && item?.unit == "Not equal to") { 

    // }
    // else if (item?.type == "numeric" && item?.unit == "Equal to") {

    //  }

    if (item?.type == "numeric" && item?.unit == "Number limit") {
      const min = parseInt(Number(item?.numberLowerLimit), 10);
      const max = parseInt(Number(item?.numberUpperLimit), 10);
      if (!isNaN(min) && !isNaN(max) && min <= max) {
        const result = [];
        for (let i = min; i <= max; i++) {
          result.push(i);
        }

        if (newValue?.length > 0 && result.includes(Number(newValue[0]))) {

          newValue = newValue.slice(1);
          const updatedFields = dataList.map(field => {
            if (field.id === list?.id) {
              return {
                ...field,
                value: newValue
              };
            }
            return field;
          });
          setDataList(updatedFields);
          return false

        } else {

          const updatedFields = dataList.map(field => {
            if (field.id === list?.id) {
              return {
                ...field,
                value: newValue
              };
            }
            return field;
          });
          setDataList(updatedFields);
        }
      }


    }
    else if (item?.type == "numeric" && item?.unit == "Maximum length") {
      if (newValue.length >= item?.value) {
        newValue = newValue.slice(0, item?.value);
        const updatedFields = dataList.map(field => {
          if (field.id === list?.id) {
            return {
              ...field,
              value: newValue
            };
          }
          return field;
        });
        setDataList(updatedFields);
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id === list?.id) {
            return {
              ...field,
              value: newValue
            };
          }
          return field;
        });
        setDataList(updatedFields);
      }
    }
    else if (item?.type == "numeric" && item?.unit == "Numbers (0-9) only") {


      newValue = newValue.replace(/\D/g, '');

      const updatedFields = dataList.map(field => {
        if (field.id === list?.id) {
          return {
            ...field,
            value: newValue
          };
        }
        return field;
      });
      setDataList(updatedFields);
      return false



    }
    // else if (item?.type == "numeric" && item?.unit == "Specify number range") { }
    // else if (item?.type == "numeric" && item?.unit == "Integer Only") { }
    // else if (item?.type == "numeric" && item?.unit == "Decimal Only with  precision and scale") { }
    // else if (item?.type == "numeric" && item?.unit == "Positive number only") { }
    // else if (item?.type == "numeric" && item?.unit == "Negative number only") { }
    // else if (item?.type == "text" && item?.unit == "Required") { }
    else if (item?.type == "text" && item?.unit == "Not equal to") {

      const regex = new RegExp(item.value, 'g');
      newValue = newValue.replace(regex, '');
      const updatedFields = dataList.map(field => {
        if (field.id === list?.id) {
          return {
            ...field,
            value: newValue
          };
        }
        return field;
      });
      setDataList(updatedFields);
    }
    // else if (item?.type == "text" && item?.unit == "Equal to") { }
    else if (item?.type == "text" && item?.unit == "Maximum length") {

      if (newValue.length >= Number(item?.value)) {
        newValue = newValue.slice(0, Number(item?.value));
        const updatedFields = dataList.map(field => {
          if (field.id === list?.id) {
            return {
              ...field,
              value: newValue
            };
          }
          return field;
        });
        setDataList(updatedFields);
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id === list?.id) {
            return {
              ...field,
              value: newValue
            };
          }
          return field;
        });
        setDataList(updatedFields);
      }
    }
    // else if (item?.type == "text" && item?.unit == "Lowercase (a-z) only") { }
    // else if (item?.type == "text" && item?.unit == "Uppercase (A-Z) only") { }
    else if (item?.type == "text" && item?.unit == "Alphabets (a-zA-Z) only") {
      newValue = newValue.replace(/[^a-zA-Z\s]/g, '');
      const updatedFields = dataList.map(field => {
        if (field.id === list?.id) {
          return {
            ...field,
            value: newValue
          };
        }
        return field;
      });
      setDataList(updatedFields);
    }
    // else if (item?.type == "text" && item?.unit == "Special characters (@$!%*?&)") { }
    // else if (item?.type == "text" && item?.unit == "Email only") { }
    // else if (item?.type == "text" && item?.unit == "Website only") { }
  };
}
export const validateonKeyUpHandle = (list, value, dataList, setDataList) => {
  const data = JSON.parse(list?.validations)
  const eee = JSON.parse(data?.validation)?.onKeyUp
  for (let item of eee) {
    if (item?.type == "numeric" && item?.unit == "Required") {
      if (!value || value.trim() == "") {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "numeric" && item?.unit == "Less than") {
      if (value.length < Number(item?.value)) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "numeric" && item?.unit == "Greater than") {
      if (value?.length > Number(item?.value)) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "numeric" && item?.unit == "Not equal to") {
      if (Number(value) !== Number(item?.value)) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "numeric" && item?.unit == "Equal to") {
      if (Number(value) == Number(item?.value)) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "numeric" && item?.unit == "Maximum length") {
      if (value?.length > Number(item?.value)) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "numeric" && item?.unit == "Numbers (0-9) only") {
      if (!(/[0-9]/.test(value))) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "numeric" && item?.unit == "Number limit") {
      const min = Number(item?.numberLowerLimit);
      const max = Number(item?.numberUpperLimit);
      if (!isNaN(min) && !isNaN(max) && min <= max) {
        const result = [];
        for (let i = min; i <= max; i++) {
          result.push(`${i}`);
        }
        if (result.includes(value.charAt(0))) {
          const updatedFields = dataList.map(field => {
            if (field.id == list?.id) {
              return {
                ...field,
                error: { hi: item?.messageHindi, en: item?.messageEnglish }
              };
            }
            return field;
          });
          setDataList(updatedFields)
          return false
        } else {
          const updatedFields = dataList.map(field => {
            if (field.id == list?.id) {
              return {
                ...field,
                error: ""
              };
            }
            return field;
          });
          setDataList(updatedFields)
        }
      }

    }
    else if (item?.type == "numeric" && item?.unit == "Specify number range") {
      const min = Number(item?.numberLowerLimit);
      const max = Number(item?.numberUpperLimit);
      if (Number(value) < min && Number(value) > max) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "numeric" && item?.unit == "Integer Only") {
      if (!(/^-?\d*$/.test(Number(item?.value)))) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "numeric" && item?.unit == "Decimal Only with  precision and scale") {
      if (!(/^\d*\.?\d{0,2}$/.test(Number(item?.value)))) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "numeric" && item?.unit == "Positive number only") {
      if (!(/^\d*$/.test(Number(item?.value)))) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "numeric" && item?.unit == "Negative number only") {
      if (!(/^-?\d*$/.test(Number(item?.value))) && !(Number(item?.value) === '-' || Number(item?.value) !== '')) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "text" && item?.unit == "Required") {
      if (!value) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              value: value,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              value: value,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "text" && item?.unit == "Not equal to") {
      if (value?.length !== Number(item?.value)) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "text" && item?.unit == "Equal to") {
      if (value?.length == Number(item?.value)) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }

    }
    else if (item?.type == "text" && item?.unit == "Maximum length") {
      if (value?.length > Number(item?.value)) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "text" && item?.unit == "Lowercase (a-z) only") {
      if (!(/[a-z]/.test(value))) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "text" && item?.unit == "Uppercase (A-Z) only") {
      if (!(/[A-Z]/.test(value))) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "text" && item?.unit == "Alphabets (a-zA-Z) only") {
      if (!(/^[a-zA-Z]*$/.test(value))) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "text" && item?.unit == "Special characters (@$!%*?&)") {
      if (!(/[\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]/.test(value))) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "text" && item?.unit == "Email only") {
      if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value))) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }
    else if (item?.type == "text" && item?.unit == "Website only") {
      const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;
      if (!(urlPattern.test(value))) {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: { hi: item?.messageHindi, en: item?.messageEnglish }
            };
          }
          return field;
        });
        setDataList(updatedFields)
        return false
      } else {
        const updatedFields = dataList.map(field => {
          if (field.id == list?.id) {
            return {
              ...field,
              error: ""
            };
          }
          return field;
        });
        setDataList(updatedFields)
      }
    }

  }
}