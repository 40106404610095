/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import { Imageurl } from '../../../Utils/Utils'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom'
import { HiPlus } from 'react-icons/hi'
import { deleteCategoryData, getCategoryListData, getCategoryListDataSort, handleEnablecategoryDisableData } from '../../../services/api/admin'
import ReactPaginate from 'react-paginate';
import { Message } from '../../../components/message'
import { useLocation } from "react-router-dom";
import { formatDateWithSlash } from '../../../Utils/common'

const Category = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { state } = useLocation();
    useEffect(() => {
        if (state && state.message) {
            setApiMessage({ type: 'success', message: state.message });
            window.scrollTo(0, 0);
            // Clear the message once it is rendered
            window.history.replaceState({}, document.title);
        }
    }, [state])

    const navigate = useNavigate()
    const [categoryList, setCategoryList] = useState([])
    const [deleteData, setDeleteData] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [orderBy, setOrderBy] = useState("asc");
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [sortBy, setSortBy] = useState('category_name')
    const [searchData, setSearchData] = useState('')

    //category list
    const getCategoryList = async (pageNo) => {
        try {
            const categoryList = await getCategoryListData(pageNo)
            const data = categoryList?.data?.data
            if (categoryList?.status == 200) {
                setCategoryList(data)
                setTotalPages(Math.ceil(categoryList?.data?.total / categoryList?.data?.per_page)); // Calculate total pages
            } else {
                setApiMessage({ type: 'success', message: categoryList?.message });
            }
        } catch (err) {
            console.log("error on categoryList: ", err)
            setApiMessage({ type: 'error', message: "error occured" });
        }
    };


    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        searchSortFromList(sortBy, selectedPage.selected + 1, orderBy, searchData); // Increment by 1 if the API starts page numbers from 1
    };

    const searchSortFromList = async (sortBy, pageNo, orderBy, searchBy) => {
        try {
            setSearchData(searchBy)
            setOrderBy(orderBy)
            setSortBy(sortBy)
            const categoryList = await getCategoryListDataSort(sortBy, orderBy, searchBy, pageNo)
            setTotalPages(Math.ceil(categoryList?.data?.total / categoryList?.data?.per_page));
            setCurrentPage(pageNo)
            const data = categoryList?.data?.data
            setCategoryList(data)
        } catch (err) {
            console.log("error on categoryList: ", err)
        }
    }

    // const searchSortFromList = async (e, sortBy) => {
    //     try {
    //         const searchBy = e.target.value ? e.target.value : '';
    //         setOrderBy(orderBy == 'asc' ? 'desc' : 'asc')
    //         const categoryList = await getCategoryListDataSort(sortBy, orderBy, searchBy)
    //         const data = categoryList?.data?.data?.filter(i => i?.status !== "0")
    //         setCategoryList(data)
    //     } catch (err) {
    //         console.log("error on categoryList: ", err)
    //         // navigate("/")
    //         setApiMessage({ type: 'error', message: "error occured" });

    //     }
    // }

    useEffect(() => {
        getCategoryList(currentPage)
    }, [])

    // delete Category
    const deleteCategory = async (obj) => {
        try {
            const formData = new FormData();
            formData.append("category_id", obj.id)
            await deleteCategoryData(formData)
            searchSortFromList(sortBy, currentPage, orderBy, searchData);
            // Close the modal after deletion
            setDeleteData("");
        } catch (error) {
            console.error(error);
        }
    }

    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("category_id", item?.id)
            formData.append("status", e?.value)
            const data = await handleEnablecategoryDisableData(formData)
            if (data?.status == 200) {
                searchSortFromList(sortBy, currentPage, orderBy, searchData);
                setApiMessage({ type: 'success', message: data?.message });
            } else if (data?.status == 401) {
                setApiMessage({ type: 'error', message: data?.message });
            } else {
                setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                    <div>
                        <b onClick={() => navigate("/admin/")}>Home  /</b><span>Category</span>
                    </div>
                    <button className='adm-tab-btn' onClick={() => navigate("/admin/category/add")}><span><HiPlus />
                    </span> Add Category</button>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="adm-tabs-outer d-flex justify-content-between">
                            <h4 className='mb-0'>Category List </h4>   <input className='w-25 px-2 form-control' placeholder='search' type="text" onChange={(e) => searchSortFromList(sortBy, 1, orderBy, e.target.value) } />
                        </div>
                        {/* <div className="col-md-12 aod-resp-tab">
                            <div className='suf-input-box aod-form-input mb-2'>
                                <input className='w-25' placeholder='search' type="text" onChange={(e) => searchSortFromList(e, 'category_name')} />
                            </div>
                        </div> */}
                        <div className="aod-head"></div>

                        <div className="ad-reg-table">
                            <Table size="sm" className='ad-cat-table mt-4'>
                                <thead>
                                    <tr>
                                        <th className='ad-sno'>S No</th>
                                        <th className='ad-sno'> Image</th>
                                        <th className='ad-long'> English Name <span><i class="fa-solid fa-sort" onClick={(e) =>  searchSortFromList('category_name', currentPage, orderBy == 'asc' ? 'desc' : 'asc', searchData)}></i></span></th>
                                        <th className='ad-long'> Hindi Name <span><i class="fa-solid fa-sort" onClick={(e) => searchSortFromList('category_name_hi', currentPage, orderBy == 'asc' ? 'desc' : 'asc', searchData)}></i></span></th>
                                        <th className='ad-long'> Description <span><i class="fa-solid fa-sort" onClick={(e) => searchSortFromList('category_description', currentPage, orderBy == 'asc' ? 'desc' : 'asc', searchData)}></i></span></th>
                                        <th className='ad-long'>Created At <span><i class="fa-solid fa-sort" onClick={(e) => searchSortFromList('created_at', currentPage, orderBy == 'asc' ? 'desc' : 'asc', searchData)}></i></span></th>
                                        <th className='ad-long'>Status</th>
                                        <th className='ad-long'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        categoryList?.map((item, index) => (
                                            <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                                                <td>{currentPage == 0 ? index + 1 : (((currentPage - 1) * 10) + (index + 1))}</td>
                                                <td>{item?.category_image ? <img className='ad-cat-td-img' src={`${Imageurl()}/${item?.category_image}`} alt="" /> : "--"}</td>
                                                <td>{item?.category_name ? item?.category_name : "--"}</td>
                                                <td>{item?.category_name_hi ? item?.category_name_hi : "--"}</td>
                                                <td>{item?.category_description ? item?.category_description : "--"}</td>
                                                <td>{item?.created_at ? formatDateWithSlash(item?.created_at) : '--'}</td>
                                                <td>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={item?.status == 1}
                                                            onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                </td>
                                                <td><span className='ad-cat-edit' onClick={() => { navigate("/admin/category/update", { state: item }) }}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            <div className="d-flex w-100 justify-content-end">
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={totalPages} // Total number of pages from API
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageChange} // Method to handle page click
                                    forcePage={currentPage - 1}
                                    containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this Category ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2 d-flex flex-row">
                        <button className='mx-2' onClick={() => { deleteCategory(deleteData) }}>Yes</button>
                        <button className='suf-can-butt mx-2' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </AdminDashboard>
    )
}

export default Category



