import apiInstance from "./config";

export const getCategoryListData = async (pageNo) => {
    return await apiInstance.get(`/admin/categories?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getCategoryListDataSort = async (sortBy, orderBy, serachBy, pageNo) => {
    return await apiInstance.get(`/admin/categories?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getSubCategoryListDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/sub-categories?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteCategoryData = async (payload) => {
    return await apiInstance.post('/admin/categories/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const addCategoryData = async (payload) => {
    return await apiInstance.post('/admin/categories/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateSubCategoryData = async (payload) => {
    return await apiInstance.post('/admin/categories/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getFontFamilyData = async () => {
    return await apiInstance.get('/get-fontfamilies')
        .then(res => res)
        .catch(err => err);
}

export const getSubCategoryListData = async (pageNo) => {
    return await apiInstance.get(`/admin/sub-categories?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getRegistrationFieldsListData = async () => {
    return await apiInstance.get('/admin/registration-form/fields')
        .then(res => res)
        .catch(err => err);
}

export const addRegistrationFieldData = async (payload) => {
    return await apiInstance.post('/admin/registration-form/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const deleteRegistrationFieldData = async (payload) => {
    return await apiInstance.post('/admin/registration-form/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const getValidationsListData = async () => {
    return await apiInstance.get('/admin/validation-rules')
        .then(res => res)
        .catch(err => err);
}

export const updateRegistrationFieldData = async (payload) => {
    return await apiInstance.post('/admin/registration-form/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const addSubCategoryTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getFormTabListDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/categories/tabs?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const getFormTabListData = async (pageNo) => {
    return await apiInstance.get(`/admin/categories/tabs?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteApplicationTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateSubCategoryTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateSubCategoryTabSectionData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/update', payload)
        .then(res => res)
        .catch(err => err);
}


export const handleEnableDisableCategoryData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/enable-disable', payload)
        .then(res => res)
        .catch(err => err);
}

export const addSubCategorySectionTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getFormTabSubCategoryListData = async (payload) => {
    return await apiInstance.post('admin/categories/tabs/list', payload)
        .then(res => res)
        .catch(err => err);
}

export const getSectionsData = async (pageNo) => {
    return await apiInstance.get(`/admin/categories/tabs/sections?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}
export const getSectionsDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/categories/tabs/sections?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteApplicationSectionsTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const handleEnableSectionsDisableData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const addFormFieldData = async (payload) => {
    return await apiInstance.post('/admin/sections/fields/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getSectionListDataByTabId = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/by-tab_id', payload)
        .then(res => res)
        .catch(err => err);
}

export const getFormFieldsListData = async (pageNo) => {
    return await apiInstance.get(`/admin/sections/fields?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getFormFieldListDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/sections/fields?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteFormFieldData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/fields/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateFormFieldData = async (payload) => {
    return await apiInstance.post('/admin/sections/fields/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const handleEnableDisableFormFieldsData = async (payload) => {
    return await apiInstance.post('/admin/sections/fields/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getUsersListData = async () => {
    return await apiInstance.get('/admin/users')
        .then(res => res)
        .catch(err => err);
}

export const getLoginFieldsData = async () => {
    return await apiInstance.get('/login-form')
        .then(res => res)
        .catch(err => err);
}

export const updateLoginFieldData = async (payload) => {
    return await apiInstance.post('/admin/login-form/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getResetPasswordFieldData = async () => {
    return await apiInstance.get('/admin/reset-password-form')
        .then(res => res)
        .catch(err => err);
}


export const updateResetPasswordFieldData = async (payload) => {
    return await apiInstance.post('/admin/reset-password-form/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getForgotPasswordFieldsData = async () => {
    return await apiInstance.get('/admin/forgot-password-form')
        .then(res => res)
        .catch(err => err);
}

export const updateForgotPasswordFieldsData = async (payload) => {
    return await apiInstance.post('/admin/forgot-password-form/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getGuidelinesFieldsData = async () => {
    return await apiInstance.get('admin/guidelines')
        .then(res => res)
        .catch(err => err);
}

export const addGuidelinesFieldsData = async (payload) => {
    return await apiInstance.post('/admin/guidelines/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateGuidelinesFieldsData = async (payload) => {
    return await apiInstance.post('/admin/guidelines/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const deleteGuidelinesFieldsData = async (payload) => {
    return await apiInstance.post('/admin/guidelines/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const activeInactiveTabs = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getCategoriesDropdown = async () => {
    return await apiInstance.get('/admin/categories-dropdown')
        .then(res => res)
        .catch(err => err);
}

export const getSubCategoriesDropdown = async () => {
    return await apiInstance.get('/admin/sub-categories-dropdown')
        .then(res => res)
        .catch(err => err);
}

export const addRolesData = async (payload) => {
    return await apiInstance.post('/admin/roles/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateRolesData = async (payload) => {
    return await apiInstance.post('/admin/roles/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getRolesListData = async (pageNo) => {
    return await apiInstance.get(`/admin/roles?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getRolesAllListData = async () => {
    return await apiInstance.get(`/admin/roles/list`)
        .then(res => res)
        .catch(err => err);
}

export const getEnableDisableRolesData = async (payload) => {
    return await apiInstance.post('/admin/roles/enable-disable', payload)
        .then(res => res)
        .catch(err => err);
}

export const getRoleListDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/roles?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteRoleData = async (id) => {
    return await apiInstance.delete(`/admin/roles/delete/${id}`)
        .then(res => res)
        .catch(err => err);
}

export const handleEnablecategoryDisableData = async (payload) => {
    return await apiInstance.post('/admin/categories/enable-disable', payload)
        .then(res => res)
        .catch(err => err);
}

export const totalApplications = async () => {
    return await apiInstance.get(`/admin/applications/total`)
        .then(res => res)
        .catch(err => err);
}

export const activeApplication = async () => {
    return await apiInstance.get(`applications/active`)
        .then(res => res)
        .catch(err => err);
}

export const inActiveapplication = async () => {
    return await apiInstance.get(`applications/inactive`)
        .then(res => res)
        .catch(err => err);
}

export const totalPendingApplication = async () => {
    return await apiInstance.get(`/admin/applications/pending`)
        .then(res => res)
        .catch(err => err);
}

export const totalApprovedApplication = async () => {
    return await apiInstance.get(`/admin/applications/approved`)
        .then(res => res)
        .catch(err => err);
}

export const totalRejectedApplication = async () => {
    return await apiInstance.get(`/admin/applications/rejected`)
        .then(res => res)
        .catch(err => err);
}

export const totalRenewApplication = async () => {
    return await apiInstance.get(`/admin/applications/renew`)
        .then(res => res)
        .catch(err => err);
}

export const totalUpDateApplications = async () => {
    return await apiInstance.get(`/admin/applications/update`)
        .then(res => res)
        .catch(err => err);
}

export const frontendUser = async () => {
    return await apiInstance.get(`/frontend/users`)
        .then(res => res)
        .catch(err => err);
}

export const totalAdminUser = async () => {
    return await apiInstance.get(`/admin/users`)
        .then(res => res)
        .catch(err => err);
}

export const totalExpiredcertificate = async () => {
    return await apiInstance.get(`/certificates/expired`)
        .then(res => res)
        .catch(err => err);
}

export const expiredCertificateIn60days = async () => {
    return await apiInstance.get(`/certificates/expired/sexty-days`)
        .then(res => res)
        .catch(err => err);
}

export const expiredCertificateIn30days = async () => {
    return await apiInstance.get(`/certificates/expired/thirty-days`)
        .then(res => res)
        .catch(err => err);
}

export const districtWiseApplication = async () => {
    return await apiInstance.get(`/district/applications`)
        .then(res => res)
        .catch(err => err);
}

export const applicationDistrictByCategoryId = async () => {
    return await apiInstance.get(`/district/applications/category_id`)
        .then(res => res)
        .catch(err => err);
}

export const applicationDistrictBySubCategoryId = async () => {
    return await apiInstance.get(`/district/applications/sub_category_id`)
        .then(res => res)
        .catch(err => err);
}

export const allModuleListData = async () => {
    return await apiInstance.get(`/admin/modules`)
        .then(res => res)
        .catch(err => err);
}

export const allSubModuleByModuleIdListData = async (payload) => {
    return await apiInstance.post(`/admin/sub-modules`, payload)
        .then(res => res)
        .catch(err => err);
}

export const allActiveUsers = async () => {
    return await apiInstance.get(`/admin/total-active`)
        .then(res => res)
        .catch(err => err);
}

export const allInActiveUsers = async () => {
    return await apiInstance.get(`/admin/total-inactive`)
        .then(res => res)
        .catch(err => err);
}

export const postUserPermission = async (payload) => {
    return await apiInstance.post(`/admin/modules/permission/create`, payload)
        .then(res => res)
        .catch(err => err);
}

export const updatePermissionsData = async (payload) => {
    return await apiInstance.post(`/admin/modules/permission/update`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getPermissionDataById = async (payload) => {
    return await apiInstance.post(`/admin/modules/permission/details`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getPermissionDataByRoleId = async (payload) => {
    return await apiInstance.post(`/admin/roles/permission/details`, payload)
        .then(res => res)
        .catch(err => err);
}

export const allAdminUserList = async (pageNo) => {
    return await apiInstance.get(`/admin/users?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getEnableDisableAdminUserData = async (payload, id) => {
    return await apiInstance.post(`/admin/users/active-inactive/${id}`, payload)
}

export const getStateListData = async (pageNo) => {
    return await apiInstance.get(`/admin/states?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const allAdminUserDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/users?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
}
export const addStateData = async (payload) => {
    return await apiInstance.post('/admin/states/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getStateListDataSort = async (sortBy, orderBy, serachBy, pageNo) => {
    return await apiInstance.get(`/admin/states?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const updateStateData = async (payload) => {
    return await apiInstance.post('/admin/states/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const activeInactiveState = async (payload) => {
    return await apiInstance.post('/admin/states/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const deleteStateData = async (payload) => {
    return await apiInstance.post('/admin/states/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const addDistrictData = async (payload) => {
    return await apiInstance.post('/admin/districts/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getStateDropdown = async () => {
    return await apiInstance.get('/states/all')
        .then(res => res)
        .catch(err => err);
}

export const getDistrictListData = async (pageNo) => {
    return await apiInstance.get(`/admin/districts?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getDistrictListDataSort = async (sortBy, orderBy, serachBy, pageNo, stateId) => {
    return await apiInstance.get(`/admin/districts?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}&state_id=${stateId}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteDistrictData = async (payload) => {
    return await apiInstance.post('admin/districts/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateDistrictData = async (payload) => {
    return await apiInstance.post('/admin/districts/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const activeInactiveDistrict = async (payload) => {
    return await apiInstance.post('/admin/districts/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getDistrictDropdown = async () => {
    return await apiInstance.get('/districts/all')
        .then(res => res)
        .catch(err => err);
}

export const getCityListData = async (pageNo) => {
    return await apiInstance.get(`/admin/cities?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const addCityData = async (payload) => {
    return await apiInstance.post('/admin/cities/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getCityListDataSort = async (sortBy, orderBy, serachBy, pageNo, stateId, districtId) => {
    return await apiInstance.get(`/admin/cities?page=${pageNo}&sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&state_id=${stateId}&district_id=${districtId}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteCityData = async (payload) => {
    return await apiInstance.post('admin/cities/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateCityData = async (payload) => {
    return await apiInstance.post('/admin/cities/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const activeInactiveCity = async (payload) => {
    return await apiInstance.post('/admin/cities/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getDistrictListDataByState = async (payload) => {
    return await await apiInstance.post('/state/districts', payload)
        .then(res => res)
        .catch(err => err);
}


export const getUserPermissionListData = async (pageNo) => {
    return await apiInstance.get(`/admin/module/permissions?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getEnableDisablePermissionData = async (payload) => {
    return await apiInstance.post('/admin/roles/modules/permission/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getUserPermissionListDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/module/permissions?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const deletePermissionData = async (id) => {
    return await apiInstance.delete(`/admin/modules/permission/delete/${id}`)
        .then(res => res)
        .catch(err => err);
}

export const submitAdminUserData = async (payload) => {
    return await apiInstance.post(`/admin/users/add`, payload)
        .then(res => res)
        .catch(err => err);
}

export const updateAdminUserData = async (payload) => {
    return await apiInstance.post(`/admin/users/update`, payload)
        .then(res => res)
        .catch(err => err);
}

export const deleteAdminUserData = async (id) => {
    return await apiInstance.delete(`/admin/users/delete/${id}`)
        .then(res => res)
        .catch(err => err);
}

export const fetchAllCategoryFaq = async (pageNo) => {
    return await apiInstance.get(`/admin/sub-categories/faqs?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getAllCategoryFaqSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/sub-categories/faqs?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteCategoryFaqById = async (payload) => {
    return await apiInstance.post(`/admin/sub-categories/faqs/delete`, payload)
        .then(res => res)
        .catch(err => err);
}

export const submitCategoryFaqData = async (payload) => {
    return await apiInstance.post(`/admin/sub-categories/faqs/create`, payload)
        .then(res => res)
        .catch(err => err);
}

export const updateCategoryFaqdData = async (payload) => {
    return await apiInstance.post(`/admin/sub-categories/faqs/update`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getEnableDisableCategoryFaqData = async (payload) => {
    return await apiInstance.post('/admin/sub-categories/faqs/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const addUserPermissionData = async (payload) => {
    return await apiInstance.post('user/permissions/add', payload)
        .then(res => res)
        .catch(err => err);
}

export const getempDesignationListData = async (pageNo) => {
    return await apiInstance.get(`/admin/employee/designations?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getEmpDesignationListDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/employee/designations?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteEmpDesignationListData = async (payload) => {
    return await apiInstance.post(`/admin/employee/designations/delete`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getEnableDisableEmpDesignationListsData = async (payload) => {
    return await apiInstance.post('/admin/employee/designations/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const submitEmpDesignationListData = async (payload) => {
    return await apiInstance.post(`/admin/employee/designations/create`, payload)
        .then(res => res)
        .catch(err => err);
}

export const updateEmpDesignationListData = async (payload) => {
    return await apiInstance.post(`/admin/employee/designations/update`, payload)
        .then(res => res)
        .catch(err => err);
}

export const addNewDropDownList = async (payload) => {
    return await apiInstance.post(`/admin/fields/dropdown/create`, payload)
        .then(res => res)
        .catch(err => err);
}