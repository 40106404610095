import apiInstance from "./config";

export const getTabs = async (payload) => {
  return await apiInstance.post('/categories/tabs/list', payload)
    .then(response => response)
    .catch(err => err);
};

export const getSection = async (payload) => {
  return await apiInstance.post('/categories/tabs/sections', payload)
    .then(response => response)
    .catch(err => err);
};

export const getSectionFields = async (payload) => {
  return await apiInstance.post('/categories/tabs/sections/fields', payload)
    .then(response => response)
    .catch(err => err);
};  