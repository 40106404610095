import React from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import { Message } from '../../../components/message';
import { useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';

const ApprovedApplications = () => {
    const navigate = useNavigate();
    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {/* {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)} */}
                <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                    <div>
                        <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b><span>Approved Application</span>
                    </div>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="adm-tabs-outer">
                            <h3 className='mb-0 w-100'>Approved Application</h3>
                        </div>
                        <div className="aod-head"></div>
                        <div className="ad-reg-table">
                            <Table size="sm" className='ad-cat-table mt-4'>
                                <thead>
                                    <tr>
                                        <th className='ad-sno'>S No</th>
                                        <th className='ad-long'>District </th>
                                        <th className='ad-long'>Tracking Number</th>
                                        <th className='ad-long'>Created At</th>
                                        <th className='ad-long'>Status</th>
                                        <th className='ad-long'>Actions</th>
                                    </tr>
                                </thead>

                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    )
}

export default ApprovedApplications;
