import React, { useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom';

const EmailSettingPage = () => {
    const navigate = useNavigate();
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    return (
        <AdminDashboard>
            <div className='aod-outer'>

            </div>

        </AdminDashboard>
    )
}

export default EmailSettingPage
