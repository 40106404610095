import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
const HeaderTop = () => {

    const { language, setLanguage } = useLanguage();
    const [fontSize, setFontSize] = useState(16);

    useEffect(() => {
        document.documentElement.style.fontSize = `${fontSize}px`;
    }, [fontSize]);

    const increaseFontSize = () => {
        setFontSize(18);
    };
    const decreaseFontSize = () => {
        setFontSize(14);
    };
    const defaultFontSize = () => {
        setFontSize(16);
    };

    const SignUpSvg = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clipPath="url(#clip0_1_2986)">
                    <path d="M8.00174 7.55816C8.61706 7.55816 9.21856 7.3757 9.73018 7.03384C10.2418 6.69199 10.6406 6.2061 10.876 5.63762C11.1115 5.06914 11.1731 4.4436 11.0531 3.8401C10.933 3.23661 10.6367 2.68226 10.2016 2.24716C9.76653 1.81207 9.21218 1.51576 8.60868 1.39572C8.00519 1.27568 7.37965 1.33729 6.81117 1.57276C6.24268 1.80823 5.75679 2.20699 5.41494 2.71861C5.07309 3.23023 4.89062 3.83173 4.89062 4.44705C4.89062 5.27217 5.2184 6.06349 5.80185 6.64694C6.38529 7.23038 7.17662 7.55816 8.00174 7.55816ZM8.00174 2.22483C8.44125 2.22483 8.87089 2.35516 9.23634 2.59934C9.60178 2.84352 9.88661 3.19058 10.0548 3.59664C10.223 4.0027 10.267 4.44951 10.1813 4.88058C10.0955 5.31165 9.88387 5.70761 9.57308 6.0184C9.2623 6.32918 8.86634 6.54083 8.43527 6.62657C8.0042 6.71232 7.55739 6.66831 7.15133 6.50012C6.74527 6.33192 6.39821 6.04709 6.15403 5.68165C5.90984 5.31621 5.77951 4.88656 5.77951 4.44705C5.77951 3.85768 6.01364 3.29245 6.43039 2.8757C6.84713 2.45895 7.41237 2.22483 8.00174 2.22483Z" fill="#03341C" />
                    <path d="M13.5427 10.8304C12.8299 10.077 11.971 9.477 11.0185 9.06691C10.0659 8.65682 9.03973 8.44531 8.00266 8.44531C6.96558 8.44531 5.93941 8.65682 4.98686 9.06691C4.03431 9.477 3.17541 10.077 2.46266 10.8304C2.30813 10.9954 2.22231 11.2132 2.22266 11.4393V13.777C2.22266 14.0128 2.31631 14.2389 2.48301 14.4056C2.64971 14.5723 2.8758 14.6659 3.11155 14.6659H12.8893C13.1251 14.6659 13.3512 14.5723 13.5179 14.4056C13.6846 14.2389 13.7782 14.0128 13.7782 13.777V11.4393C13.7798 11.2138 13.6956 10.9961 13.5427 10.8304ZM12.8893 13.777H3.11155V11.4348C3.74143 10.7716 4.49967 10.2434 5.34015 9.8825C6.18062 9.52159 7.08575 9.33547 8.00043 9.33547C8.91512 9.33547 9.82024 9.52159 10.6607 9.8825C11.5012 10.2434 12.2594 10.7716 12.8893 11.4348V13.777Z" fill="#03341C" />
                </g>
                <defs>
                    <clipPath id="clip0_1_2986">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        )
    }
    const SignInSvg = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M7.98702 13.3307V12.6641H12.257C12.3592 12.6641 12.4532 12.6214 12.539 12.5361C12.6244 12.4503 12.667 12.3563 12.667 12.2541V3.74073C12.667 3.63851 12.6244 3.54451 12.539 3.45873C12.4532 3.3734 12.3592 3.33073 12.257 3.33073H7.98702V2.66406H12.2564C12.563 2.66406 12.819 2.76695 13.0244 2.97273C13.2301 3.17806 13.333 3.43406 13.333 3.74073V12.2541C13.333 12.5607 13.2304 12.8167 13.025 13.0221C12.8192 13.2278 12.563 13.3307 12.2564 13.3307H7.98702ZM7.51369 10.3561L7.04502 9.87673L8.59169 8.33073H2.67969V7.66406H8.59169L7.04502 6.1174L7.51302 5.63873L9.87235 7.9974L7.51369 10.3561Z" fill="#03341C" />
            </svg>
        )
    }
    return (
        <div className="hdt-main">
            <div className='w-100 d-flex justify-content-between align-items-center hd-cont hdt-cont container px-5'>
                <div class="text-left"><p className='mb-0 text-white'>{language ? " उत्तराखंड पर्यटन विकास बोर्ड" : "Uttarakhand Tourism Development Board"} </p></div>
                <div className="hdt-links">
                    <div className="hdt-links-div">
                        <NavLink to="/signup" className='hdt-inner-link'><span className='pe-1'><SignUpSvg /></span>{language ? " साइन अप " : "Sign Up"} </NavLink>

                        <NavLink to="/login" className='hdt-inner-link'><span className='pe-1'><SignInSvg /></span>{language ? "साइन इन" : "Sign In "} </NavLink>
                    </div>
                    <div className='hdt-links-div-sec'>
                        <div className="hdt-language d-flex mx-3">
                            <label className="form-check-label mx-2 " htmlFor="english">
                                English
                            </label>
                            <div className="hdt-toggle-block form-check  form-switch">
                                <input className="form-check-input lightgreen flexSwitchCheckChecked" checked={language} type="checkbox" role="switch" id="changeLang" value="in" onChange={() => { setLanguage(!language) }} />
                            </div>
                            <label className="form-check-label mx-1 " htmlFor="hindi">
                                हिंदी
                            </label>
                        </div>
                        <div className="hdt-btn">
                            <button onClick={increaseFontSize}>A<sup>+</sup></button>
                            <button onClick={defaultFontSize}>A</button>
                            <button onClick={decreaseFontSize}>A<sup>-</sup></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HeaderTop