import "./App.css";
import Home from "./pages/OuterPages/Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Auth/Login/Login";
import SignUp from "./pages/Auth/SignUp/SignUp";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import Dashboard from "./pages/Dashboard/Dashboard";

import Guidelines from "./pages/Guidelines/Guidelines";
import Application from "./pages/Application/Application";
import DashboardCategory from "./pages/DashboardCategory/DashboardCategory";
import { useEffect } from "react";
import { Apiurl } from "./Utils/Utils";
import axios from "axios";
import CategoryInfo from "./pages/CategoryInfo/CategoryInfo";
import Category from "./pages/Admin/Category/Category";
import SubCategory from "./pages/Admin/SubCategory/SubCategory";
import DashboardLayout from "./pages/Admin/DashboardLayout/DashboardLayout";
import RegistrationForm from "./pages/Admin/RegistrationForm/RegistrationForm";
import ApplicationTabs from "./pages/Admin/ApplicationTabs/ApplicationTabs";
import ApplicationSections from "./pages/Admin/ApplicationSections/ApplicationSections";
import FormFields from "./pages/Admin/FormFields/FormFields";
import Users from "./pages/Admin/Users/Users";
import { LanguageProvider } from "./components/LanguageProvider/LanguageProvider ";
import AdminLogin from "./pages/Admin/AdminLogin/AdminLogin";
import AdminResetPassword from "./pages/Admin/AdminResetPassword/AdminResetPassword";
import AdminForgotPassword from "./pages/Admin/AdminForgotPassword/AdminForgotPassword";
import RegistrationFormAdd from "./pages/Admin/RegistrationForm/RegistrationFormAdd";
import AdminLoginAdd from "./pages/Admin/AdminLogin/AdminLoginAdd";
import AdminResetPasswordAdd from "./pages/Admin/AdminResetPassword/AdminResetPasswordAdd";
import AdminForgotPasswordAdd from "./pages/Admin/AdminForgotPassword/AdminForgotPasswordAdd";
import CategoryAdd from "./pages/Admin/Category/CategoryAdd";
import SubCategoryAdd from "./pages/Admin/SubCategory/SubCategoryAdd";
import AdminGuidelines from "./pages/Admin/AdminGuidelines/AdminGuidelines";
import AdminGuidelineslist from "./pages/Admin/AdminGuidelines/AdminGuidelineslist";
import AddRoles from "./pages/Admin/userRoles/AddRoles";
import RolesList from "./pages/Admin/userRoles/RolesList";
import UserPermissions from "./pages/Admin/userRoles/user-permissions";
import AdminUsers from "./pages/Admin/AdminUsers/AdminUsers";
import UserPermissionList from "./pages/Admin/userRoles/userPermissionList";
import ViewData from "./components/viewData/viewData";
import States from "./pages/Admin/SettingsTabs/States";
import Districts from "./pages/Admin/SettingsTabs/Districts";
import Cities from "./pages/Admin/SettingsTabs/Cities";
import AddAdminUser from "./pages/Admin/AdminUsers/AddAdminUser";
import AdminCategoryFaq from "./pages/Admin/AdminCategoryFaq/AdminCategoryFaq";
import AddAdminCategoryFaqs from "./pages/Admin/AdminCategoryFaq/AddAdminCategoryFaqs";
import EmployeeDesignation from "./pages/Admin/Employee Designation/EmployeeDesignation";
import AddEmployeeDesignation from "./pages/Admin/Employee Designation/AddEmployeeDesignation";
import TotalApplication from "./pages/Admin/Application/TotalApplication";
import PendingApplication from "./pages/Admin/Application/PendingApplication";
import RejectedApplications from "./pages/Admin/Application/RejectedApplications";
import ApprovedApplications from "./pages/Admin/Application/ApprovedApplications";
import RenewalApplications from "./pages/Admin/Application/RenewalApplications";
import UpdateApplicationRequst from "./pages/Admin/Application/UpdateApplicationRequst";
import AddDropDown from "./pages/Admin/dropDown/AddDropDown";
import DropDownList from "./pages/Admin/dropDown/DropDownList";
import SmsSettingPage from "./pages/Admin/setting Page/SmsSettingPage";
import EmailSettingPage from "./pages/Admin/setting Page/EmailSettingPage";
import UserLog from "./pages/Admin/user-log/UserLog";

function App() {
  const ClearCache = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${Apiurl()}/clear-cache`,
    };
    axios(option)
      .then((e) => { })
      .catch((error) => { });
  };
  useEffect(() => {
    ClearCache();
  }, []);

  return (
    <LanguageProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/categoryInfo" element={<CategoryInfo />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/guidelines" element={<Guidelines />} />
          <Route path="/category" element={<DashboardCategory />} />
          <Route path="/application/*" element={<Application />} />
          {/* Admin Routes */}
          <Route path="/admin/view" element={<ViewData />} />
          <Route path="/admin/category" element={<Category />} />
          <Route path="/admin/category/:name" element={<CategoryAdd />} />
          <Route path="/admin/sub-category" element={<SubCategory />} />
          <Route
            path="/admin/sub-category/:name"
            element={<SubCategoryAdd />}
          />
          <Route path="/admin" element={<DashboardLayout />} />
          <Route path="/admin/registration" element={<RegistrationForm />} />
          <Route
            path="/admin/registration/:name"
            element={<RegistrationFormAdd />}
          />
          <Route path="/admin/application-tabs" element={<ApplicationTabs />} />
          <Route
            path="/admin/application-sections"
            element={<ApplicationSections />}
          />
          <Route path="/admin/application-form" element={<FormFields />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/login/:name" element={<AdminLoginAdd />} />
          <Route
            path="/admin/reset-password"
            element={<AdminResetPassword />}
          />
          <Route
            path="/admin/reset-password/:name"
            element={<AdminResetPasswordAdd />}
          />
          <Route
            path="/admin/forgot-password"
            element={<AdminForgotPassword />}
          />
          <Route
            path="/admin/forgot-password/:name"
            element={<AdminForgotPasswordAdd />}
          />
          <Route path="/admin/guidelines/:name" element={<AdminGuidelines />} />
          <Route path="/admin/guidelines" element={<AdminGuidelineslist />} />
          <Route path="/admin/roles/:name" element={<AddRoles />} />
          <Route path="/admin/roles" element={<RolesList />} />
          <Route path="/admin/permissions/:name" element={<UserPermissions />} />
          <Route path="/admin/user-permissions/edit" element={<UserPermissions />} />
          <Route
            path="/admin/permissions/:name"
            element={<UserPermissions />}
          />
          <Route path="/admin/permissions" element={<UserPermissionList />} />
          <Route path="/admin/admin-users" element={<AdminUsers />} />
          <Route path="/admin/permissions" element={<UserPermissions />} />
          <Route path="/admin/states" element={<States />} />
          <Route path="/admin/districts" element={<Districts />} />
          <Route path="/admin/cities" element={<Cities />} />
          <Route path="/admin/admin-user/:name" element={<AddAdminUser />} />
          <Route
            path="/admin/adminCategory-faq"
            element={<AdminCategoryFaq />}
          />
          <Route path="/admin/FAQs/:name" element={<AddAdminCategoryFaqs />} />
          <Route path="/admin/Employee-Designation" element={<EmployeeDesignation />} />
          <Route path="/admin/Employee-Designation/:name" element={<AddEmployeeDesignation />} />

          <Route path="/admin/total-applications" element={<TotalApplication />} />
          <Route path="/admin/pending-applications" element={<PendingApplication />} />
          <Route path="/admin/rejected-applications" element={<RejectedApplications />} />
          <Route path="/admin/approved-applications" element={<ApprovedApplications />} />
          <Route path="/admin/renewal-applications" element={<RenewalApplications />} />
          <Route path="/admin/update-app-requests" element={<UpdateApplicationRequst />} />
          <Route path="/admin/other-DropDown/:name" element={<AddDropDown />} />
          <Route path="/admin/other-DropDown-list" element={<DropDownList />} />
          <Route path="/admin/sms-setting" element={<SmsSettingPage />} />
          <Route path="/admin/email-setting" element={<EmailSettingPage />} />
          <Route path="/admin/user-log" element={<UserLog />} />

        </Routes>
      </BrowserRouter>
    </LanguageProvider>
  );
}
export default App;
