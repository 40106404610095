
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { BsChevronLeft } from 'react-icons/bs'
import { HiPlus } from 'react-icons/hi'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { useForm, useFieldArray } from "react-hook-form"
import { useLocation, useNavigate } from 'react-router-dom'
import { addNewDropDownList, updateStateData } from '../../../services/api/admin'
import { checkPattern } from '../../../Utils/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

const AddDropDown = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [nameEn, setNameEn] = useState('');
    const [nameHi, setNameHi] = useState('');
    const [nameEnError, setNameEnError] = useState('');
    const [nameHiError, setNameHiError] = useState('');
    const [editData, setEditData] = useState();  // Holds data for edit scenario

    const { control, handleSubmit, register, reset } = useForm({
        defaultValues: {
            items: [{ name_en: "", name_hi: "" }],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "items",
    });

    // Populate form for edit scenario
    useEffect(() => {
        if (location?.state && location?.pathname == "/admin/other-DropDown/update") {
            editStoredData(location?.state)
        }
    }, [[location?.state, reset]])

    const editStoredData = (item) => {
        // const item = {
        //     "id": 8,
        //     "name_en": "asda",
        //     "name_hi": "dsfsdf",
        //     "dropdown_values": "{\"items\":[{\"name_en\":\"dsf\",\"name_hi\":\"fgg\"},{\"name_en\":\"dsf\",\"name_hi\":\"fgg\"},{\"name_en\":\"dsf\",\"name_hi\":\"fgg\"}]}",
        //     "status": "1"
        // };

        const parsedData = JSON.parse(item.dropdown_values); // Parse the dropdown values

        setEditData(item);
        setNameEn(item.name_en);
        setNameHi(item.name_hi);

        // Reset form with the parsed data
        reset({ items: parsedData.items });
    };

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        if (name === "nameEn" && (await checkPattern(value))) setNameEn(value);
        if (name === "nameHi" && (await checkPattern(value))) setNameHi(value);
    };

    const validateForm = () => {
        let isValid = true;
        if (!nameEn.trim()) {
            setNameEnError("Please Enter English Name");
            isValid = false;
        } else setNameEnError('');

        if (!nameHi.trim()) {
            setNameHiError("Please Enter Hindi Name");
            isValid = false;
        } else setNameHiError('');

        return isValid;
    };

    const onSubmit = async (data) => {
        const dropdown_values = { items: data.items };
        const formData = new FormData();
        formData.append('name_en', nameEn);
        formData.append('name_hi', nameHi);
        formData.append('dropdown_values', JSON.stringify(dropdown_values));

        try {
            if (validateForm()) {
                if (editData) {
                    formData.append("dropdown_id", editData.id);
                    formData.append('status', editData.status);

                    const UpdateData = await updateStateData(formData);  // Update logic
                    if (UpdateData?.status === 200) {
                        navigate("/admin/other-DropDown-list");
                    }
                } else {
                    const addData = await addNewDropDownList(formData);  // Add logic
                    if (addData?.status === 200) {
                        navigate("/admin/other-DropDown-list");
                    }
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                    <div>
                        <HomeIcon />
                        <b onClick={() => navigate("/admin/")}>Home /</b>
                        <span>{editData ? "Update" : "Add"} DropDown</span>
                    </div>
                </div>

                <div className="aod-inner">
                    <div className={editData ? "aod-update aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head">
                            <button onClick={() => navigate("/admin/other-DropDown-list")}>
                                <BsChevronLeft /> Back
                            </button>
                            {editData ? "Update" : "Add"} DropDown
                        </div>

                        <div className="row py-4">
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'> English Name</label>
                                    <input
                                        className='w-100'
                                        placeholder='Enter English Name'
                                        type="text"
                                        name="nameEn"
                                        value={nameEn}
                                        onChange={handleInputChange}
                                    />
                                    {nameEnError && <p className='validate-error'>{nameEnError}</p>}
                                </div>
                            </div>

                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'> Hindi Name</label>
                                    <input
                                        className='w-100'
                                        placeholder='Enter Hindi Name'
                                        type="text"
                                        name="nameHi"
                                        value={nameHi}
                                        onChange={handleInputChange}
                                    />
                                    {nameHiError && <p className='validate-error'>{nameHiError}</p>}
                                </div>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label className='mb-1 w-100 fw-semibold'>Dropdown Options</label>
                            {fields.map((item, index) => (
                                <div className="row py-1" key={item.id}>
                                    <div className="col-md-4 aod-resp-tab">
                                        <div className='suf-input-box aod-form-input mb-2'>
                                            <input
                                                className='w-100 mb-2'
                                                {...register(`items.${index}.name_en`)}
                                                placeholder="Name (EN)"
                                                required
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-4 aod-resp-tab">
                                        <div className='suf-input-box aod-form-input mb-2'>

                                            <input
                                                className='w-100 mb-2'
                                                {...register(`items.${index}.name_hi`)}
                                                placeholder="Name (HI)"
                                                required
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-4 aod-resp-tab">
                                        <div className='suf-input-box aod-form-input mb-2'>
                                            <button className='dlt-btn cta-f' type="button" onClick={() => remove(index)}>

                                                <FontAwesomeIcon icon={faTrashCan} className="trash-icon" />
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="gap-4 d-flex justify-content-between">
                                        <button className='add-btn  cta-f add-btn'
                                            type="button"
                                            onClick={() => append({ name_en: "", name_hi: "" })}
                                        >   <FontAwesomeIcon icon={faPlus} className="me-1" />
                                            Add More
                                        </button>
                                        <button className=" ad-reset-btn" type="submit">
                                            {editData ? "Update DropDown values" : "Add DropDown value"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    );
};

export default AddDropDown;
