import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom'
import { activeInactiveState, addStateData, deleteStateData, getFontFamilyData, getStateListData, getStateListDataSort, updateStateData } from '../../../services/api/admin'
import { BsChevronLeft } from 'react-icons/bs'
import '../AdminGuidelines/AdminGuildlines.css'
import { HiPlus } from 'react-icons/hi'
import ReactPaginate from 'react-paginate'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { Message } from '../../../components/message'
import { checkPattern } from '../../../Utils/common'
import { formatDateWithSlash } from '../../../Utils/common'

const States = () => {
    const navigate = useNavigate();
    const [nameEn, setNameEn] = useState('')
    const [nameHi, setNameHi] = useState('')
    const [searchData, setSearchData] = useState('')
    const [sortByState, setSortByState] = useState('created_at')
    const [nameEnError, setNameEnError] = useState()
    const [nameHiError, setNameHiError] = useState()
    const [editData, setEditData] = useState()
    const [stateList, setStateList] = useState()
    const [fontFamily, setFontFamily] = useState();
    const [fontFamilyError, setFontFamilyError] = useState();
    const [fontColor, setFontColor] = useState();
    const [fontColorError, setFontColorError] = useState();
    const [fontSize, setFontSize] = useState('');
    const [fontSizeError, setFontSizeError] = useState();
    const [backgroundColor, setBackgroundColor] = useState();
    const [backgroundColorError, setBackgroundColorError] = useState();
    const [fontsList, setFontsList] = useState()
    const [deleteData, setDeleteData] = useState()
    const [editContainer, setEditContainer] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [orderByState, setOrderByState] = useState("desc");
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });

    //validations
    const validateForm = (item) => {
        let isValid = true;
        if (item === "nameEn" || item === "both") {
            if (!nameEn || nameEn.trim() === "") {
                setNameEnError("Please Enter English Name");
                isValid = false;
            } else {
                setNameEnError("");
            }
        }
        if (item === "nameHi" || item === "both") {
            if (!nameHi || nameHi.trim() === "") {
                setNameHiError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setNameHiError("");
            }
        }

        // if (item === "family" || item === "both") {
        //     if (!fontFamily) {
        //         setFontFamilyError("Please Select Font Family");
        //         isValid = false;
        //     } else {
        //         setFontFamilyError("");
        //     }
        // }

        // if (item === "color" || item === "both") {
        //     if (!fontColor || fontColor.trim() === "") {
        //         setFontColorError("Please Select Font Color");
        //         isValid = false;
        //     } else {
        //         setFontColorError("");
        //     }
        // }

        // if (item === "fontSize" || item === "both") {
        //     if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
        //         setFontSizeError("Please Enter Valid Font Size");
        //         isValid = false;
        //     } else {
        //         setFontSizeError("");
        //     }
        // }

        // if (item === "bgcolor" || item === "both") {
        //     if (!backgroundColor || backgroundColor.trim() === "") {
        //         setBackgroundColorError("Please Select Background Color");
        //         isValid = false;
        //     } else if (backgroundColor == fontColor) {
        //         setBackgroundColorError("Font color and background color cannot be same.");
        //         isValid = false;
        //     } else {
        //         setBackgroundColorError("");
        //     }
        // }

        return isValid;
    };
    // add state
    const addState = async () => {
        if (!validateForm("both")) return
        try {
            const formData = new FormData();
            formData.append("name_en", nameEn)
            formData.append("name_hi", nameHi)
            // formData.append("font_family_id", fontFamily?.value)
            // formData.append("font_color", fontColor)
            // formData.append("font_size", fontSize)
            // formData.append("background_color", backgroundColor)
            const data = await addStateData(formData)
            if (data.status == 200) {
                setEditContainer(false)
                setNameEn("")
                setNameHi("")
                // setFontFamily("");
                // setFontColor("")
                // setFontSize("")
                // setBackgroundColor("")
                getStateData("created_at", "1", "desc", "")
                setApiMessage({ type: 'success', message: "State added" });
            } else if (data.status == 403) {
                setApiMessage({ type: 'error', message: data.message });
            } else {
                setApiMessage({ type: 'error', message: data.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: "error occured" });
        }
    }

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        getStateData(sortByState, selectedPage.selected + 1, orderByState, searchData);
    };

     // state list
    const getStateData = async (sortBy, pageNo, orderBy, searchBy) => {
        try {
            setSearchData(searchBy)
            setOrderByState(orderBy)
            setSortByState(sortBy)
            const districtList = await getStateListDataSort(sortBy, orderBy, searchBy, pageNo)
            setTotalPages(Math.ceil(districtList?.data?.total / districtList?.data?.per_page));
            setCurrentPage(pageNo)
            const data = districtList?.data?.data
            setStateList(data)
        } catch (err) {
            console.log("error on stateList: ", err)
        }
    }

    //get font families
    const getFontFamily = async () => {
        try {
            const fontFamilyData = await getFontFamilyData()
            const data = fontFamilyData?.data?.map(i => ({
                label: i?.name, value: i?.id
            }))

            setFontsList(data)
        } catch (err) {
            console.log("font families error :", err)
        }
    };

    useEffect(() => {
        //getFontFamily()
        getStateData("created_at", "1", "desc", "")
    }, [])

    // delete state
    const deleteState = async (item) => {
        try {
            const formData = new FormData();
            formData.append("state_id", item?.id)
            const data = await deleteStateData(formData)
            if (data.status == 200) {
                setEditContainer(false)
                setDeleteData("")
                getStateData(sortByState, currentPage, orderByState, searchData)
            }
        } catch (error) {
            console.error(error);
        }
    }
    //edit state
    const editState = (item) => {
        setEditData(item)
        setNameEn(item?.name_en);
        setNameHi(item?.name_hi)
        // setFontColor(item?.font_color)
        // setFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
        // setFontSize(item?.font_size)
        // setBackgroundColor(item?.background_color)
        window.scrollTo(0, 0);
    }
    //update state
    const updateState = async () => {
        if (!validateForm("both")) return
        try {
            const formData = new FormData();
            formData.append("state_id", editData?.id)
            formData.append("name_en", nameEn)
            formData.append("name_hi", nameHi)
            // formData.append("font_family_id", fontFamily?.value)
            // formData.append("font_color", fontColor)
            // formData.append("font_size", fontSize)
            // formData.append("sort", 1)
            // formData.append("background_color", backgroundColor)
            const data = await updateStateData(formData)
            if (data.status == 200) {
                setEditContainer(false)
                setNameEn("")
                setNameHi("")
                // setFontFamily("");
                // setFontColor("")
                // setFontSize("")
                // setBackgroundColor("")
                setEditData("")
                getStateData("created_at", "1", "desc", "")
                setApiMessage({ type: 'success', message: "State updated" });
            } else if (data.status == 403) {
                setApiMessage({ type: 'error', message: data.message });
            } else {
                setApiMessage({ type: 'error', message: data.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: "error occured" });
        }
    }
    const handleEmptyData = () => {
        setEditContainer(false)
        setNameEn("")
        setNameHi("")
        // setFontFamily("");
        // setFontColor("")
        // setFontSize("")
        // setBackgroundColor("")
        setNameEnError("")
        setNameHiError("")
        // setFontFamilyError("");
        // setFontColorError("")
        // setFontSizeError("")
        // setBackgroundColorError("")
        setEditData()
    }
    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("state_id", item?.id)
            formData.append("status", e?.value)
            const data = await activeInactiveState(formData)
            if (data?.status == 200) {
                getStateData(sortByState, currentPage, orderByState, searchData)
            } else if (data?.status == 401) {
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    }

    const handleEditBack = async () => {
        setEditContainer(false)
        setSearchData('')
        setNameEnError('')
        setNameHiError('')
        setCurrentPage(1)
        setSortByState('name_en')
        setOrderByState('asc')
        getStateData("created_at", "1", "desc", "")
    }

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        const pattern = /^[a-zA-Z\s]*$/;

        // Check for "nameEn" field
        if (name === "nameEn") {

            if (await checkPattern(value)) {
                setNameEnError("")
                setNameEn(value);
            } else {
                e.preventDefault()
                return;
            }
        }

        // Check for "nameHi" field
        if (name === "nameHi") {
            if (await checkPattern(value)) {
                setNameHiError("")
                setNameHi(value);
            } else {
                e.preventDefault()
                return;
            }
        }
    };

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                {editContainer ? <>
                    <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                        <div>
                            <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span>States</span>

                        </div>
                    </div>
                    <div className="aod-inner">
                        <div className={editData?.id ? "aod-update aod-bottom" : "aod-bottom"}>
                            <div className="aod-head text-center adm-head"> <button onClick={() => handleEditBack()}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} State</div>
                            <div className="row py-4">
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            English Name
                                        </label>
                                        <input className='w-100' placeholder='Enter English Name' type="text" name="nameEn" value={nameEn} maxLength={100} onChange={handleInputChange} />

                                        {nameEnError && <p className='validate-error'>{nameEnError}</p>}

                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            Hindi Name
                                        </label>
                                        <input className='w-100' placeholder='Enter Hindi Name' type="text" name="nameHi" value={nameHi} maxLength={100} onChange={handleInputChange} />

                                        {nameHiError && <p className='validate-error'>{nameHiError}</p>}
                                    </div>
                                </div>
                                {/* <div className="col-md-4 aod-resp-tab d-none">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Family</label>
                                        <Select
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            value={fontFamily}
                                            options={fontsList}
                                            onChange={(e) => { setFontFamily(e); setFontFamilyError("") }}
                                            placeholder="Select Font Family"
                                            onMenuOpen={() => handleKeyUpLoginDetails("family")}
                                            onMenuClose={() => handleKeyUpLoginDetails("family")}
                                        />
                                        {fontFamilyError && <p className='validate-error'>{fontFamilyError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 aod-resp-tab d-none">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Color</label>
                                        <label className='adm-label-color'>
                                            {fontColor ? fontColor : <span>Select Font Color</span>}
                                            <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("color")} onBlur={() => handleKeyUpLoginDetails("color")} value={fontColor} onChange={(e) => setFontColor(e?.target?.value)} />
                                        </label>
                                        {fontColorError && <p className='validate-error'>{fontColorError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab d-none">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Size (in pixels)</label>
                                        <input className='w-100' max={20} placeholder='Enter Font Size' type="number" value={fontSize} onKeyUp={() => handleKeyUpLoginDetails("size")}
                                            onKeyDown={(e) => {
                                                if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={async (e) => {
                                                const value = e.target.value;

                                                if (value > 0) {
                                                    if (await checkPatternFontSize(value)) {
                                                        setFontSize(value);
                                                        setFontSizeError("")
                                                    }
                                                } else {
                                                    setFontSize("");

                                                }
                                            }} />
                                        {fontSizeError && <p className='validate-error'>{fontSizeError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 aod-resp-tab d-none">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Background Color</label>
                                        <label className='adm-label-color'>
                                            {backgroundColor ? backgroundColor : <span>Select Background Color</span>}
                                            <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("bgcolor")} onBlur={() => handleKeyUpLoginDetails("bgcolor")} value={backgroundColor} onChange={(e) => setBackgroundColor(e?.target?.value)} />
                                        </label>
                                        {backgroundColorError && <p className='validate-error'>{backgroundColorError}</p>}
                                    </div>
                                </div> */}
                            </div>
                            <div className="row ad-cat-row">
                                {
                                    editData?.id ?
                                        <>
                                            <div className="col-md-6 gap-4 d-flex">
                                                <button onClick={() => updateState()}>
                                                    Update
                                                </button> </div></> :
                                        <div className="col-md-3">
                                            <button onClick={addState}>
                                                Add
                                            </button></div>
                                }
                            </div>

                        </div>
                    </div>
                </> :
                    <>
                        <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                            <div>
                                <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span>States</span>

                            </div>
                            <button className='adm-tab-btn' onClick={() => setEditContainer(true)}><span><HiPlus />
                            </span> Add State</button>
                        </div>
                        <div className="aod-inner pt-0">
                            <div className="aod-bottom">
                                <div className="adm-tabs-outer d-flex justify-content-between d-md-flex justify-content-between">
                                    <h4 className='mb-0 w-100'>States List</h4>
                                    <div className='suf-input-box aod-form-input mb-2 ms-3 w-50'>
                                        <input className='w-100 form-control' placeholder='search' type="text" onChange={(e) => getStateData(sortByState, 1, orderByState, e.target.value)} />
                                    </div>
                                </div>
                                <div className="aod-head"></div>
                                <div className="ad-reg-table">
                                    <Table size="sm" className='ad-cat-table mt-4'>
                                        <thead>
                                            <tr>
                                                <th className='ad-sno'>S No</th>
                                                <th className='ad-long'>English Name <span><i class="fa-solid fa-sort" onClick={(e) => getStateData('name_en', currentPage, orderByState == 'asc' ? 'desc' : 'asc', searchData)}></i></span></th>
                                                <th className='ad-long'>Hindi Name <span><i class="fa-solid fa-sort" onClick={(e) => getStateData('name_hi', currentPage, orderByState == 'asc' ? 'desc' : 'asc', searchData)}></i></span></th>
                                                <th className='ad-long'>Created At <span><i class="fa-solid fa-sort" onClick={(e) => getStateData('created_at', currentPage, orderByState == 'asc' ? 'desc' : 'asc', searchData)}></i></span></th>
                                                <th className='ad-long'>Status</th>
                                                <th className='ad-long'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                stateList?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{currentPage == 0 ? index + 1 : (((currentPage - 1) * 10) + (index + 1))}</td>

                                                        <td>{item?.name_en}</td>
                                                        <td>{item?.name_hi}</td>
                                                        <td>{item?.created_at ? formatDateWithSlash(item?.created_at) : '--'}</td>

                                                        <td>
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={item?.status == 1}
                                                                    onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                                />
                                                                <span className="slider"></span>
                                                            </label>
                                                        </td>

                                                        <td><span className='ad-cat-edit' onClick={() => { editState(item); setEditContainer(true) }}><EditIcon /></span><span className='ad-cat-delete d-none' onClick={() => setDeleteData(item)}><DeleteIcon /></span></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                    <div className="d-flex w-100 justify-content-end">
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={totalPages} // Total number of pages from API
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={1}
                                            onPageChange={handlePageChange} // Method to handle page click
                                            forcePage={currentPage - 1}
                                            containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this State ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2">
                        <button onClick={() => { deleteState(deleteData) }}>Yes</button>
                        <button className='suf-can-butt' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </AdminDashboard>
    )
}

export default States