import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useLocation, useNavigate } from 'react-router-dom'
import { Message } from '../../../components/message'
import { BsChevronLeft } from 'react-icons/bs'
import { GetLocalStorage } from '../../../Utils/Utils'
import { getRolesAllListData, getRolesListData, submitAdminUserData, updateAdminUserData } from '../../../services/api/admin'
import Select from 'react-select';
import { checkPattern, checkPatternWithSpecialCharacter, checkValidEmail, checkValidMobile } from '../../../Utils/common'
import { UploadIcon } from '../AdminIcon'

const AddAdminUser = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const token = GetLocalStorage("userInfo")
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    // const [editData, setEditData] = useState()
    const [roleList, setRoleList] = useState([]);
    const [roleId, setRoleId] = useState('');
    const [image, setImage] = useState();
    const [imageError, setImageError] = useState();
    const [userId, setUserId] = useState('')
    const [formData, setFormData] = useState({
        name: '',
        userName: '',
        email: '',
        mobileNo: '',
    })
    const [error, setError] = useState({
        name: '',
        userName: '',
        email: '',
        mobileNo: '',
        image: '',
        roleId: ''
    })

    useEffect(() => {
        if (!token) {
            navigate("/")
        } else {
            if (location?.state && location?.pathname == "/admin/admin-user/update") {
                editAdminUser(location?.state)
            }
        }
    }, [location?.state])

    useEffect(() => {
        getRolesList();
    }, []);

    const getRolesList = async () => {
        try {
            const RolesData = await getRolesAllListData()
            const data = RolesData?.data?.map(i => ({
                label: i?.title, value: i?.id
            }))
            setRoleList(data)
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }

    const handleInputChange = async (e) => {
        const { name, value } = e.target
        const cursorPosition = e.target.selectionStart;
        // console.log(name, value)
        if (name == 'name') {
            if (await checkPattern(value) == false) {
                e.preventDefault()
                return;
            }
        } else if (name == 'userName') {
            if (await checkPatternWithSpecialCharacter(value) == false) {
                e.preventDefault()
                return;
            }
        } else if (name == 'mobileNo') {
            if (!/^\d*$/.test(value)) {
                return;

            }
            if (value.length === 1 && /^[0-5]$/.test(value)) {
                return;
            }
            // if (value.length == 10) {
            //     // Setting mobile no here because it is causing delay for the 10th digit to set
            //     setFormData(prevState => ({
            //         ...prevState,
            //         [name]: value
            //     }));
            // }
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);

        validateOnChangeHandle(name, value);
    }

    const validateOnChangeHandle = async (name, value) => {
        let formErrors = error;
        switch (name) {
            case 'name':
                if (!value?.trim()) {
                    formErrors.name = 'Please Enter the user Name';
                } else {
                    formErrors.name = ''
                }
                break;
            case 'userName':
                if (!value?.trim()) {
                    formErrors.userName = 'Please Enter the user UserName';
                } else {
                    formErrors.userName = ''
                }
                break;
            case 'email':
                if (!value?.trim()) {
                    formErrors.email = 'Please Enter the user Email';
                } else {
                    formErrors.email = ''
                }
                break;
            case 'mobileNo':
                if (!value?.trim()) {
                    formErrors.mobileNo = 'Please Enter the user Mobile Number';
                } else {
                    formErrors.mobileNo = ''
                }
                break;
        }
        setError(formErrors);
        return formErrors;
    }

    const validateOnBlurHandle = async (e) => {
        const { name, value } = e?.target;

        // Create a copy of the current errors state
        let formErrors = { ...error };

        if (name === 'name') {
            if (!value.trim()) {
                formErrors.name = 'Please enter the user name';
            } else {
                formErrors.name = '';
            }
        } else if (name === 'userName') {
            if (!value.trim()) {
                formErrors.userName = 'Please enter the user username';
            } else {
                formErrors.userName = '';
            }
        } else if (name === 'email') {
            if (!value.trim()) {
                formErrors.email = 'Please enter the user email';
            } else {
                const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!emailPattern.test(value)) {
                    formErrors.email = 'Please enter a valid email';
                } else {
                    formErrors.email = '';
                }
            }
        } else if (name === 'mobileNo') {
            if (!value.trim()) {
                formErrors.mobileNo = 'Please enter the user mobile number';
            } else {
                formErrors.mobileNo = '';
            }
        }

        // Update the error state
        setError(formErrors);
    };

    const validateRole = () => {
        let formErrors = { ...error };
        if (!roleId) {
            formErrors.roleId = 'please select User role'
        } else {
            formErrors.catId = '';
        }
        setError(formErrors);
    }

    const handleFileChange = (e) => {
        let formErrors = { ...error };
        const file = e?.target?.files?.[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(file);
                formErrors.image = ""
            }
            else {
                setImage(null);
                // formErrors.image = "Please upload an image file."
                e.target.value = '';
            }
        }
        setError(formErrors);
    };

    const editAdminUser = (data) => {
        setFormData({
            name: data?.name,
            userName: data?.username,
            email: data?.email,
            mobileNo: data?.email
        })
        setUserId(data.id)
        setImage(data?.upload_photo)
        setRoleId(data?.role_id)

    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            let validationErrors = { ...error };
            await Promise.all(Object.keys(formData).map(async (key) => {
                const value = formData[key];
                const errors = await validateOnChangeHandle(key, value);
                if (!roleId) {
                    error.roleId = " Please Select user Role"
                }
                if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
                    error.email = 'Please Enter the valid user Email';
                }
                // if (!image) {
                //     error.image = 'Please upload image'
                // }
                validationErrors = { ...validationErrors, ...errors };

            }));
            setError(validationErrors);
            if (Object.values(validationErrors).some(error => error !== '')) {
                return;
            }


            const AdminUserData = new FormData();
            AdminUserData.append("name", formData.name)
            AdminUserData.append("username", formData.userName)
            AdminUserData.append("email", formData.email)
            AdminUserData.append("mobile_number", formData.mobileNo)
            AdminUserData.append("role_id", roleId)
            AdminUserData.append("upload_photo", image?.name ? image : "")


            const userData = await submitAdminUserData(AdminUserData)
            if (userData?.status == 200) {
                navigate("/admin/admin-users", { state: { message: userData.message } })
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err.message })
        }
    }

    const updateFields = async (e) => {
        try {
            e.preventDefault();
            console.log("sdjsjdh", roleId)
            let validationErrors = { ...error };
            await Promise.all(Object.keys(formData).map(async (key) => {
                const value = formData[key];
                const errors = await validateOnChangeHandle(key, value);
                if (!roleId) {
                    error.roleId = " Please Select user Role"
                }
                if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
                    error.email = 'Please Enter the valid user Email';
                }
                // if (!image) {
                //     error.image = 'Please upload image'
                // }
                validationErrors = { ...validationErrors, ...errors };

            }));
            setError(validationErrors);
            if (Object.values(validationErrors).some(error => error !== '')) {
                return;
            }


            const AdminUserData = new FormData();
            AdminUserData.append("name", formData.name)
            AdminUserData.append("username", formData.userName)
            AdminUserData.append("id", userId)
            AdminUserData.append("email", formData.email)
            AdminUserData.append("mobile_number", formData.mobileNo)
            AdminUserData.append("role_id", roleId)
            AdminUserData.append("upload_photo", image?.name ? image : "")
            const userData = await updateAdminUserData(AdminUserData)
            if (userData?.status == 200) {
                navigate("/admin/admin-users", { state: { message: userData.message } })
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err.message })
        }
    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                    <div>
                        <b onClick={() => navigate("/admin/")}>Home  /</b><span>Category Form</span>
                    </div>
                </div>
                <div className="aod-inner">
                    <div className={userId ? "aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/admin-users")}><BsChevronLeft /> Back</button>{userId ? "Update" : "Add"} User</div>
                        <div className="row py-4">
                            <form>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            Name
                                        </label>
                                        <input
                                            className='w-100'
                                            maxLength={100}
                                            name='name'
                                            placeholder='Enter User Name '
                                            type="text"
                                            onChange={(e) => handleInputChange(e)} // Use onChange for real-time validation
                                            onBlur={(e) => validateOnBlurHandle(e)}
                                            value={formData.name}
                                        />
                                        {error.name && <p className='validate-error'>{error.name}</p>}

                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            UserName
                                        </label>
                                        <input
                                            className='w-100'
                                            maxLength={100}
                                            name='userName'
                                            placeholder='Enter UserName '
                                            type="text"
                                            onChange={(e) => handleInputChange(e)}
                                            onBlur={(e) => validateOnBlurHandle(e)} // Use onChange for real-time validation
                                            value={formData.userName}
                                        />
                                        {error.userName && <p className='validate-error'>{error.userName}</p>}

                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            Email
                                        </label>
                                        <input
                                            className='w-100'
                                            maxLength={100}
                                            name='email'
                                            placeholder='Enter User Email '
                                            type="email"
                                            onChange={(e) => handleInputChange(e)}
                                            onBlur={(e) => validateOnBlurHandle(e)} // Use onChange for real-time validation
                                            value={formData.email}
                                        />
                                        {error.email && <p className='validate-error'>{error.email}</p>}

                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            Mobile number
                                        </label>
                                        <input
                                            className='w-100'
                                            maxLength={10}
                                            name='mobileNo'
                                            placeholder='Enter User Mobile Number '
                                            type="text"
                                            onChange={(e) => handleInputChange(e)}
                                            onBlur={(e) => validateOnBlurHandle(e)} // Use onChange for real-time validation
                                            value={formData.mobileNo}
                                        />

                                        {error.mobileNo && <p className='validate-error'>{error.mobileNo}</p>}

                                    </div>
                                </div>

                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label htmlFor="roles" className='mb-1 w-100'><span className="en-lable">Select User Role</span>
                                        </label>

                                        <Select
                                            className="my-select selectpicker"
                                            classNamePrefix="ad-menu_open_select_class"
                                            aria-label="Default select example"
                                            name="role"
                                            value={roleList?.find(option => option.value === roleId) || null}
                                            options={roleList}
                                            onBlur={validateRole}
                                            onChange={(selectedOption) => {
                                                setRoleId(selectedOption.value)
                                                error.roleId = ''
                                            }}// Use onChange for real-time validation
                                            placeholder="Select user Role"
                                        />
                                        {error.roleId && <p className='validate-error'>{error.roleId}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>
                                            Icon
                                        </label>
                                        <input maxLength={60} type="file" name="partsuploadfile" id="profileimg" className="w-100 ad-cat-input-field-image" accept="image/*" onChange={(e) => handleFileChange(e)} />
                                        <label htmlFor="profileimg" className="ad-cat-input-upload-image p-0">
                                            <p className="ad-cat-text-upload-image">
                                                <span><UploadIcon /></span> Choose Icon
                                            </p>
                                        </label>
                                        {
                                            typeof image === 'string' ? (
                                                <p className="validate-error">{image}</p>
                                            ) : (
                                                image?.name ? (
                                                    <p className="validate-error">{image?.name}</p>
                                                ) : (
                                                    error.image ? <p className="validate-error">{error.image}</p> : ""
                                                )
                                            )
                                        }


                                    </div>
                                </div>
                                <div className="row ad-cat-row">
                                    <div className="row ad-cat-row">
                                        {
                                            userId ?
                                                <>
                                                    <div className="w-auto gap-4 d-flex">
                                                        <button onClick={(e) => updateFields(e)}>
                                                            Update User
                                                        </button> </div>
                                                </> :
                                                <div className="w-auto">
                                                    <button onClick={(e) => handleSubmit(e)}>
                                                        Add User
                                                    </button>
                                                </div>
                                        }
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    )
}

export default AddAdminUser;
