import AdminDashboard from '../AdminDashboard/AdminDashboard'
import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { deleteGuidelinesFieldsData, getGuidelinesFieldsData, getSubCategoriesDropdown, getSubCategoryListData } from '../../../services/api/admin'
import '../AdminGuidelines/AdminGuildlines.css'
import { HiPlus } from 'react-icons/hi'
import ReactPaginate from 'react-paginate'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { Message } from '../../../components/message'
import { GetLocalStorage, RemoveSession } from '../../../Utils/Utils'
import { formatDateWithSlash } from '../../../Utils/common'
import { FaPencilAlt } from 'react-icons/fa'


const DropDownList = () => {
    const navigate = useNavigate();
    const [guidelineslist, setGuidelineslist] = useState()
    const [subCategoryList, setSubCategoryList] = useState([])
    const [deleteData, setDeleteData] = useState('')
    const token = GetLocalStorage("userInfo")
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const { state } = useLocation();

    useEffect(() => {
        if (!token) {
            navigate("/")
        } else {
            guidlinesData();
        }
    }, [])

    useEffect(() => {
        if (state && state.message) {
            setApiMessage({ type: 'success', message: state.message });
            // Clear the message once it is rendered
            window.scrollTo(0, 0);
            window.history.replaceState({}, document.title);
        }
    }, [state])


    const guidlinesData = async () => {
        try {
            const data = await getGuidelinesFieldsData()
            if (data?.status == 200) {
                setGuidelineslist(data?.data?.data) // Correctly sets the guidelines list
            } else {
                console.log("error")
                setApiMessage({ type: 'error', message: "error occured" });
            }
        } catch (error) {
            console.log("error on tab list: ", error)
            setApiMessage({ type: 'error', message: "error occured" });
        }
    };

    useEffect(() => {
        const getSubCategoryList = async () => {
            try {
                const subCategoryTabData = await getSubCategoriesDropdown()
                const data = subCategoryTabData?.data?.map(i => ({
                    label: i?.category_name, value: i?.id
                }))
                setSubCategoryList(data)
            } catch (error) {
                console.log("fetch subcategory list", error)
                if (error?.code === "ERR_NETWORK") {
                    RemoveSession(navigate);

                }
            };
        };
        getSubCategoryList();

    }, [])

    const deleteFormField = async (item) => {
        try {
            const payload = {
                guideline_id: item.id,
                category_id: item.category_id,
            }

            const res = await deleteGuidelinesFieldsData(payload)
            console.log(res)
            if (res?.status == 200) {
                setDeleteData('')
                guidlinesData();
            }

        } catch (e) {

        }
    }

    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("status", e?.value)
            // await getEnableDisableAdminUserData(formData, item.id)
            // getAdminUserList(currentPage)
        } catch (err) {
            setApiMessage({ type: 'err', message: err.message });
        }
    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                    <div>
                        <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b><span>Other DropDown List</span>
                    </div>
                    <button className='adm-tab-btn' onClick={() => navigate("/admin/other-DropDown/add")} ><span><HiPlus />
                    </span> Add new DropDown</button>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="adm-tabs-outer">
                            <h3 className='mb-0 w-100'>Other DropDown List</h3>
                        </div>
                        <div className="aod-head"></div>
                        <div className="ad-reg-table">
                            <Table size="sm" className='ad-cat-table mt-4'>
                                <thead>
                                    <tr>
                                        <th className='ad-sno'>S No</th>
                                        {/* <th className='ad-long'>Sub Category Id </th> */}
                                        <th className='ad-long'>English Name</th>
                                        <th className='ad-long'>Hindi Name</th>
                                        <th className='ad-long'>Created At</th>
                                        <th className='ad-long'>status</th>
                                        <th className='ad-long'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        guidelineslist?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item?.name_en ? item?.name_en : '--'}</td>
                                                <td>{item?.name_hi ? item?.name_hi : '--'}</td>
                                                <td>{item?.created_at ? formatDateWithSlash(item?.created_at) : '--'}</td>
                                                <td>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={item?.status == 1}
                                                            onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <div className='icon-col'><span className='ad-cat-edit' onClick={() => {
                                                        navigate("/admin/other-DropDown/update", { state: item })
                                                    }}>        <FaPencilAlt className='pt-1' /></span>
                                                        <span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon className="pb-1" /></span>
                                                    </div></td>
                                            </tr>

                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData('')}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this Field ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2">
                        <button onClick={() => deleteFormField(deleteData)}>Yes</button>
                        <button className='suf-can-butt' onClick={() => setDeleteData(false)}>No</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </AdminDashboard>
    )
}

export default DropDownList
