export const formatDateWithSlash = (dateTimeString) => {
    if (dateTimeString) {
        const date = new Date(dateTimeString);

        if (dateTimeString.includes('T') || dateTimeString.includes(' ')) {
            // Handle both "2024-07-31T05:18:15.000000Z" and "2024-07-26 00:00:00"
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                // hour: '2-digit',
                // minute: '2-digit',
                // second: '2-digit',
                // hour12: true
            };
            return date.toLocaleString('en-GB', options).replace(',', '');
        } else {
            // If the date format is "2024-07-30"
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }
    } else {
        return;
    }
}

export const checkPattern = async (value) => {
    const pattern = /^[a-zA-Z\u0900-\u097F\s]*$/;
    return pattern.test(value)
}

export const checkPatternWithSpecialCharacter = async (value) => {
    const pattern = /^[a-zA-Z\u0900-\u097F\s\W\d]*$/;
    return pattern.test(value)
}

export const checkPatternFontSize = async (value) => {
    const pattern = /^\d{1,2}(\.\d{1,2})?$/;
    return pattern.test(value)
}

export const checkValidEmail = async (value) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(value)
}

export const checkValidMobile = async (value) => {
    const pattern = /^\+?[0-9]{10}$/;
    return pattern.test(value)
}