import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolder, faTags, faTag, faTable, faList, faFile, faBook, faFileAlt,
  faClipboardList, faClock, faCheckCircle, faTimesCircle, faRedo, faSync,
  faSignInAlt, faKey, faUnlock, faUserPlus, faTachometerAlt, faTools, faMapMarkerAlt,
  faLandmark, faCity
} from '@fortawesome/free-solid-svg-icons';

const SideMenu = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate();

  const iconsMap = {
    faFolder, faTags, faTag, faTable, faList, faFile, faBook, faFileAlt,
    faClipboardList, faClock, faCheckCircle, faTimesCircle, faRedo, faSync,
    faSignInAlt, faKey, faUnlock, faUserPlus, faTools, faMapMarkerAlt,
    faLandmark, faCity
  };

  const menuPages = {
    menus: [
      {
        name: "Category Management",
        icon: "faFolder",
        submenus: [
          { name: "Category", path: "/admin/category", icon: "faTags" }, { name: "Sub Category", path: "/admin/sub-category", icon: "faTag" },
          { name: "Category Tabs", path: "/admin/application-tabs", icon: "faTable" }, { name: "Category Sections", path: "/admin/application-sections", icon: "faList" },
          { name: "Category Field", path: "/admin/application-form", icon: "faFile" }, { name: "Guidelines", path: "/admin/guidelines", icon: "faBook" }, { name: "FAQ", path: "/admin/adminCategory-faq", icon: "faBook" },
          { name: "Employee-Designation", path: "/admin/Employee-Designation", icon: "faTags" }
        ],
      },
      {
        name: "Application",
        icon: "faFileAlt",
        submenus: [
          { name: "Total applications", path: "/admin/total-applications", icon: "faClipboardList" }, { name: "Pending", path: "/admin/pending-applications", icon: "faClock" },
          { name: "Approved", path: "/admin/approved-applications", icon: "faCheckCircle" }, { name: "Rejected", path: "/admin/rejected-applications", icon: "faTimesCircle" },
          { name: "Renewal", path: "/admin/renewal-applications", icon: "faRedo" }, { name: "Update Application requests", path: "/admin/update-app-requests", icon: "faSync" }
        ],
      },
      {
        name: "Pages",
        icon: "faFile",
        submenus: [
          { name: "Login", path: "/admin/login", icon: "faSignInAlt" }, { name: "Reset Password", path: "/admin/reset-password", icon: "faKey" },
          { name: "Forgot Password", path: "/admin/forgot-password", icon: "faUnlock" }, { name: "Registration", path: "/admin/registration", icon: "faUserPlus" }
        ]
      },
      {
        name: "Admin Management",
        icon: "faUserPlus",
        submenus: [
          { name: "Roles", path: "/admin/roles", icon: "faUserPlus" }, { name: "Permission", path: "/admin/permissions/add", icon: "faKey" }
          , { name: "Admin User", path: "/admin/admin-users", icon: "faUserPlus" }
        ]
      },
      {
        name: "Settings Module",
        icon: "faTools",
        submenus: [
          { name: "Manage States", path: "/admin/states", icon: "faMapMarkerAlt" }, { name: "Manage Districts", path: "/admin/districts", icon: "faLandmark" }
          , { name: "Manage Cities", path: "/admin/cities", icon: "faCity" }, { name: 'Add DropDown', path: '/admin/other-DropDown-list', icon: "faCity" }
        ]
      }
    ]
  };

  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  useEffect(() => {
    const activeMenuIndex = menuPages.menus.findIndex((menu) =>
      menu.submenus.some(submenu => pathName.includes(submenu.path) && submenu.path.length > 0)
    );
    setOpenMenuIndex(activeMenuIndex !== -1 ? activeMenuIndex : null);
  }, [pathName]);

  const toggleMenu = (index) => {
    setOpenMenuIndex((prevState) => (prevState === index ? null : index));
  };

  return (
    <div className='side-menu adm-side-menu' id="LeftMenu">
      <ul className='ds-ul'>
        <li onClick={() => navigate("/admin")} >
          <button className={pathName.endsWith("/admin") ? 'bg_image_green' : 'bg_image_white first-li'}  > <FontAwesomeIcon icon={faTachometerAlt} /> <span>Dashboard</span></button>
        </li>
        {menuPages.menus.map((menu, index) => (
          <li key={menu.name} style={(openMenuIndex === index && menu.submenus.some(submenu => pathName.includes(submenu.path) && submenu.path.length > 0)) ? { backgroundColor: 'rgb(0 174 239)' } : { backgroundColor: 'none' }}>
            <button
              className='bg_image_white'
              onClick={() => toggleMenu(index)}
            ><span className="icon-text">
                <FontAwesomeIcon icon={iconsMap[menu.icon] || iconsMap.default} />
                <span style={{ width: 'auto' }}>
                  {menu.name}
                </span>
              </span>
              {menu.submenus ? (
                openMenuIndex === index ? <FaChevronDown style={{ marginLeft: '7px', marginTop: '2px' }} /> : <FaChevronRight style={{ marginLeft: '7px', marginTop: '2px' }} /> // Chevron for collapse
              ) : null}
            </button>

            {menu.submenus && openMenuIndex === index && (
              <ul className='nested-ul'>
                {menu.submenus.map((submenu) => (
                  <li
                    key={submenu.name}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(submenu.path);
                    }}
                  >
                    <button
                      className={pathName.includes(submenu.path) && submenu.path.length > 0 ? 'bg_image_green' : 'bg_image_white'}

                    >
                      <FontAwesomeIcon icon={iconsMap[submenu.icon] || iconsMap.default} />
                      <span>{submenu.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>

  );
};

export default SideMenu;
